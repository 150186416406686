import React from 'react';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ArticleIcon from '@mui/icons-material/Article';
import HistoryIcon from '@mui/icons-material/History';
import Box from '@mui/material/Box';
import { Link } from '@mui/material';
import { GridRowModes, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { InlineEditTextarea1 } from '../customcomponents/InlineEditTextarea.js';
import { InlineEditDropdown } from '../customcomponents/InlineEditDropdown.js';
// import { NotesCellDisplay } from './RenderCellExpand.js';
import { NotesCellDisplay } from '../customcomponents/RenderCellExpandV2.js';
import AppHistoryModal from '../dialogs/AppHistoryModal471';
import removeAnyBooleanFilterValueWrapper from '../customcomponents/RemoveAnyBooleanFilterValue.js';
import doesNotContainWrapper from '../customcomponents/DoesNotContainFilter.js';
import IsEqualToColumnWrapper from '../customcomponents/IsEqualToColumnFilter.js';
import IsNotEqualToColumnWrapper from '../customcomponents/IsNotEqualToColumnFilter.js';
import IsGreaterThanColumnWrapper from '../customcomponents/IsGreaterThanColumnFilter.js';
import IsGreaterThanOrEqualToColumnWrapper from '../customcomponents/IsGreaterThanOrEqualToColumnFilter.js';
import IsLessThanColumnWrapper from '../customcomponents/IsLessThanColumnFilter.js';
import IsLessThanOrEqualToColumnWrapper from '../customcomponents/IsLessThanOrEqualToColumnFilter.js';
import IsAnyOfWrapper from '../customcomponents/IsAnyOfFilter.js';
import IsNotAnyOfWrapper from '../customcomponents/IsNotAnyOfFilter.js';
// import IsBeforeXDaysWrapper from '../customcomponents/IsBeforeXDaysFilter.js';
// import IsAfterXDaysWrapper from '../customcomponents/IsAfterXDaysFilter.js';

// Returns the columns array sorted using F471_COLUMNORDER_LS_NAME in local storage.
// (This is for the original/current Form 471 Applications page table component - TrackerTable.js)
export function Form471ApplicationsColumnsDefinitionCreator1(
    handleDetailsButtonClick,
    handleEditButtonClick,
    filingWindowEditOptions,
    applicationOwnerEditOptions,
    allActiveUsers,
    taskOwnerEditOptions,
    ercAppStatusEditOptions,
    qaReviewStatusEditOptions,
    qaReviewerEditOptions,
    qaSubmitterEditOptions,
    qaEstimatedFundingRequestEditOptions,
    apiRef,
    rowHeightPref,
    F471_COLUMNORDER_LS_NAME
) {
    let col_array = Form471ApplicationsColumnsDefinitionCreator2(
        handleDetailsButtonClick,
        handleEditButtonClick,
        filingWindowEditOptions,
        applicationOwnerEditOptions,
        allActiveUsers,
        taskOwnerEditOptions,
        ercAppStatusEditOptions,
        qaReviewStatusEditOptions,
        qaReviewerEditOptions,
        qaSubmitterEditOptions,
        qaEstimatedFundingRequestEditOptions,
        apiRef,
        rowHeightPref
    );

    let col_order = JSON.parse(localStorage.getItem(F471_COLUMNORDER_LS_NAME));
    if (col_order) {
        //console.log('Using the saved (in local storage) column order.  ' + (new Date()).toISOString());

        let sorted_cols = col_array.sort(function (a, b) {
            let indexA = col_order.findIndex((element) => element === a.field);
            let indexB = col_order.findIndex((element) => element === b.field);
            return indexA - indexB;
        });

        return sorted_cols;
    } else {
        //console.log('Using the default column order.  ' + (new Date()).toISOString());
        return col_array;
    }
}

// Returns the columns array.
// (This is for the new/work-in-progress Form 471 Applications page table component - Form471TrackerAppsTable.js
// The new version handles the column sorting in TrackerDataGrid.)
export function Form471ApplicationsColumnsDefinitionCreator2(
    apiRef,
    applicantCohortMSFilterOptions,
    applicantMSFilterOptions,
    applicationOwnerEditOptions,
    allActiveUsers,
    currentColumnWidths,
    ercAppStatusEditOptions,
    filingWindowEditOptions,
    fundingProgramMSFilterOptions,
    handleCancelIconClick,
    handleDetailsButtonClick,
    handleEditApplicationNotes,
    handleEditButtonClick,
    handleEditQANotes,
    handleSaveIconClick,
    originalColumnOrderSetter,
    originalColumnWidthSetter,
    historyModalOpen,
    setHistoryModalOpen,
    activeHistoryForm,
    setActiveHistoryForm,
    rowHeightPref,
    rowModesModel,
    qaEstimatedFundingRequestEditOptions,
    qaReviewStatusEditOptions,
    qaReviewerEditOptions,
    qaSubmitterEditOptions,
    stateMSFilterOptions,
    piaReviewStatusMSFilterOptions,
    taskOwnerEditOptions
) {
    const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    const usdPrice = {
        type: 'number',
        width: 130,
        valueFormatter: ({ value }) => (value !== null ? currencyFormatter.format(value) : value),
        cellClassName: 'font-tabular-nums',
    };

    const numberAndNoCommas = {
        type: 'number', // Sort as a number..
        valueFormatter: ({ value }) => value, // ..and display without commas.
    };

    const renderMultilineHeader = (params, line1, line2, line3) => {
        return (
            <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                {line1}
                <br />
                {line2}
                {line3 && (
                    <>
                        <br />
                        {line3}
                    </>
                )}
            </Box>
        );
    };

    // const renderDateTime = (isoDateTimeString, displayTime) => {
    //     // Version 1. Input is an ISO-8601 datetime string.
    //     // Returns a React node (when there is data).
    //     if (isoDateTimeString) {
    //         try {
    //             //console.log('isoDateTimeString = ' + isoDateTimeString + ' | displayTime = ' + displayTime);
    //             let datedate = new Date(isoDateTimeString);
    //             //console.log('    datedate = ' + datedate);
    //             let text;
    //             if (!displayTime) {
    //                 text = new Intl.DateTimeFormat('en-US').format(datedate); // "12/20/2020"
    //             } else {
    //                 let dtfoptions = {
    //                     year: 'numeric',
    //                     month: 'numeric',
    //                     day: 'numeric',
    //                     hour: 'numeric',
    //                     minute: 'numeric',
    //                 };
    //                 text = new Intl.DateTimeFormat('en-US', dtfoptions).format(datedate); // "10/26/1985, 1:21 AM"
    //             }
    //             //console.log('    text = ' + text);
    //             let ttoptions = {
    //                 weekday: 'long',
    //                 year: 'numeric',
    //                 month: 'numeric',
    //                 day: 'numeric',
    //                 hour: 'numeric',
    //                 minute: 'numeric',
    //                 timeZoneName: 'short',
    //             };
    //             let titletext = new Intl.DateTimeFormat('en-US', ttoptions).format(datedate);

    //             return <span title={titletext}>{text}</span>;
    //         } catch (err) {
    //             console.error('renderDateTime error:', err);
    //         }
    //     }
    //     return isoDateTimeString;
    // };

    const renderDateTime2 = (jsDate, displayTime) => {
        // Version 2. Input is a JavaScript Date object.
        // Returns a React node (when there is data).
        if (!jsDate) {
            return null;
        }
        try {
            let text;
            if (!displayTime) {
                text = new Intl.DateTimeFormat('en-US').format(jsDate); // "12/20/2020"
            } else {
                let dtfoptions = {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                };
                text = new Intl.DateTimeFormat('en-US', dtfoptions).format(jsDate); // "10/26/1985, 1:21 AM"
            }
            let ttoptions = {
                weekday: 'long',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZoneName: 'short',
            };
            let titletext = new Intl.DateTimeFormat('en-US', ttoptions).format(jsDate);

            return <span title={titletext}>{text}</span>;
        } catch (err) {
            console.error('renderDateTime2 error:', err);
        }
        // If above failed then output something reasonable.
        return jsDate.toLocaleString();
    };

    const convert_yyyymmddString_to_JSDate = (yyyymmddString) => {
        // Input: Date string in the format "yyyy-mm-dd".
        // Output: Javascript Date object.  Time is pushed forward as per the local timezone offset.
        // For DataGrid 'date' edit fields.
        if (!(typeof yyyymmddString === 'string')) {
            console.error('Error in convert_yyyymmddString_to_JSDate: Input was not a string.');
            return null;
        }
        let jsdate1 = new Date(yyyymmddString);
        return new Date(jsdate1.getTime() + jsdate1.getTimezoneOffset() * 60 * 1000);
    };

    const convert_JSDate_to_yyyymmddString = (jsdate) => {
        // Input: Javascript Date object.
        // Output: Date string in the format "yyyy-mm-dd".  Time was pushed back as per the local timezone offset.
        // For DataGrid 'date' edit fields.
        if (!(jsdate instanceof Date)) {
            console.error('Error in convert_JSDate_to_yyyymmddString: Input was not a JavaScript Date object.');
            return null;
        }
        let jsdate2 = new Date(jsdate.getTime() - jsdate.getTimezoneOffset() * 60 * 1000);
        return jsdate2.toISOString().split('T')[0];
    };

    const create_todayJSDate = () => {
        // Create a 'today' JavaScript Date for DataGrid 'date' edit fields.
        let today = new Date();
        today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero.
        return new Date(today.getTime() + today.getTimezoneOffset() * 60 * 1000);
    };

    //============================================================
    //  For DataGrid columns:
    //  `valueGetter`, `valueSetter`, `renderCell`, etc functions
    //============================================================

    const correctTheDropdownValue = (value, ddoptions) => {
        // If value is a number, return it.  If value is text then search the dropdown options for the numeric value (id).
        if (value === null || value === undefined) {
            return 0;
        }
        if (Number.isInteger(value)) {
            return value;
        }
        if (
            value === '' ||
            value.toLowerCase() === '<default to primary contact>' ||
            value.toLowerCase() === '<not set>' ||
            value.toLowerCase() === '<blank>'
        ) {
            return 0;
        }
        let ddoption = ddoptions.find((element) => element.text === value);
        let ddvalue = ddoption?.value;
        return ddvalue;
    };

    const valueGetter_getTaskOwnerNames = (params) => {
        if (!params.row.form471app.task_users || params.row.form471app.task_users.length === 0) {
            return '';
        }
        let ary = params.row.form471app.task_users.map((tu) => {
            return getUserDisplayName(tu);
        });
        return ary.join(', ');
    };

    const valueSetter_setTaskOwnerUserID = (params) => {
        let TaskOwnerUserID = correctTheDropdownValue(params.value, taskOwnerEditOptions);
        return { ...params.row, TaskOwnerUserID };
    };

    const valueGetter_getFilingWindowName = (params) => {
        if (params.row.form471app.form471_filing_window) {
            return params.row.form471app.form471_filing_window.filing_window_name;
        }
        return '';
    };
    const valueSetter_setFilingWindowID = (params) => {
        let FilingWindowID = correctTheDropdownValue(params.value, filingWindowEditOptions);
        return { ...params.row, FilingWindowID };
    };

    const getPrimaryContactName = (params) => {
        return getUserDisplayName(params.row.primary_contact);
    };

    const getSecondaryContactNames = (params) => {
        if (!params.row.secondary_contacts || params.row.secondary_contacts.length === 0) {
            return '';
        }
        let ary = params.row.secondary_contacts.map((sc) => {
            return getUserDisplayName(sc);
        });
        return ary.join(', ');
    };

    const getUserDisplayName = (user) => {
        return user ? user.first_name + ' ' + user.last_name : '';
    };

    const createUserChips = (userlist, rowid, keyprefix) => {
        return userlist.map((u) => {
            let chipkey = (keyprefix ? keyprefix + '-' : '') + rowid + '-' + u.first_name + u.last_name;
            let chiplabel = u.first_name + ' ' + u.last_name;
            return <Chip key={chipkey} label={chiplabel} sx={{ marginRight: '2px' }} />;
        });
    };

    const getApplicantCohortNames = (params) => {
        if (!params.row.client_applicantcohorts || params.row.client_applicantcohorts.length === 0) {
            return '';
        }
        let ary = params.row.client_applicantcohorts.map((cac) => {
            return cac.applicantcohort.applicantcohort_name;
        });
        return ary.join(', ');
    };

    const getForm471AppNotes = (params) => {
        return params.row.form471app.form471_app_notes || '';
    };

    //----------  QA columns  ----------
    const valueGetter_getQASubmitterName = (params) => {
        if (params.row.qa_form471app) {
            return getUserDisplayName(params.row.qa_form471app.submitter);
        }
    };
    const valueSetter_setQASubmitterUserID = (params) => {
        let QASubmitterUserID = correctTheDropdownValue(params.value, qaSubmitterEditOptions);
        return { ...params.row, QASubmitterUserID };
    };

    const cellClassName_QAReviewStatus = (params) => {
        if (params.row.qa_form471app) {
            let qaReviewStatusId = params.row.qa_form471app.qa_review_status_id;
            if (qaReviewStatusId === 1) {
                // "QA Ready"
                return 'InlineEditable QAReviewStatus-QAReady';
            } else if (qaReviewStatusId === 2) {
                // "QA In Progress"
                return 'InlineEditable QAReviewStatus-QAInProgress';
            } else if (qaReviewStatusId === 3) {
                // "QA Returned"
                return 'InlineEditable QAReviewStatus-QAReturned';
            } else if (qaReviewStatusId === 4) {
                // "QA Passed"
                return 'InlineEditable QAReviewStatus-QAPassed';
            } else if (qaReviewStatusId === 5) {
                // "Will Not Be Reviewed"
                return 'InlineEditable QAReviewStatus-WillNotBeReviewed';
            }
        }
        return 'InlineEditable';
    };
    const valueGetter_getQAReviewStatusText = (params) => {
        if (params.row.qa_form471app && params.row.qa_form471app.qa_form471_qa_review_status) {
            return params.row.qa_form471app.qa_form471_qa_review_status.status_text;
        }
        return '';
    };
    const valueSetter_setQAReviewStatusID = (params) => {
        let QAReviewStatusID = correctTheDropdownValue(params.value, qaReviewStatusEditOptions);
        return { ...params.row, QAReviewStatusID };
    };

    const valueGetter_getQAReviewerName = (params) => {
        if (params.row.qa_form471app) {
            return getUserDisplayName(params.row.qa_form471app.reviewer);
        }
        return '';
    };
    const valueSetter_setQAReviewerUserID = (params) => {
        let QAReviewerUserID = correctTheDropdownValue(params.value, qaReviewerEditOptions);
        return { ...params.row, QAReviewerUserID };
    };

    const valueGetter_getQANotes = (params) => {
        if (params.row.qa_form471app) {
            return params.row.qa_form471app.qa_notes || '';
        }
        return '';
    };

    const valueGetter_getQAEstimatedFundingRequest = (params) => {
        if (params.row.qa_form471app) {
            return params.row.qa_form471app.qa_estimated_funding_request;
        }
        return '';
    };

    //----------  App Status columns  ----------
    const cellClassName_USACAppStatus = (params) => {
        // Returns the cell class.
        switch (params.row.usacAppStatus4Text) {
            case 'Incomplete':
                return 'USACAppStatus AppStatus-Incomplete';
            case 'Certified':
                return 'USACAppStatus AppStatus-Certified';
            case 'Committed':
                return 'USACAppStatus AppStatus-Committed';
            default:
                return 'USACAppStatus';
        }
    };

    const cellClassName_ERCAppStatus = (params) => {
        // Returns the cell class.
        if (
            params.row.form471app.erc_form471_app_status_id === 20 ||
            params.row.form471app.erc_form471_app_status_id === 21
        ) {
            // If the ERC App Status is "Ready for QA" or "Out of QA"..
            if (params.row.qa_form471app) {
                let qaReviewStatusId = params.row.qa_form471app.qa_review_status_id;
                if (qaReviewStatusId === 1) {
                    // "QA Ready"
                    return 'InlineEditable QAReviewStatus-QAReady';
                } else if (qaReviewStatusId === 2) {
                    // "QA In Progress"
                    return 'InlineEditable QAReviewStatus-QAInProgress';
                } else if (qaReviewStatusId === 3) {
                    // "QA Returned"
                    return 'InlineEditable QAReviewStatus-QAReturned';
                } else if (qaReviewStatusId === 4) {
                    // "QA Passed"
                    return 'InlineEditable QAReviewStatus-QAPassed';
                } else if (qaReviewStatusId === 5) {
                    // "Will Not Be Reviewed"
                    return 'InlineEditable QAReviewStatus-WillNotBeReviewed';
                }
            }
        }
        return 'InlineEditable';
    };
    const valueGetter_ECRAppStatusText = (params) => {
        if (params.row.form471app.erc_form471_app_status) {
            return params.row.form471app.erc_form471_app_status.status_text;
        }
        return '';
    };
    const valueSetter_ERCAppStatusID = (params) => {
        let ERCAppStatusID = correctTheDropdownValue(params.value, ercAppStatusEditOptions);
        return { ...params.row, ERCAppStatusID };
    };

    const cellClassName_AppStatusCombined = (params) => {
        // Returns the cell class.
        switch (params.row.usacAppStatus4Text) {
            case 'Incomplete':
                return 'AppStatusCombined AppStatus-Incomplete';
            case 'Certified':
                return 'AppStatusCombined AppStatus-Certified';
            case 'Committed':
                return 'AppStatusCombined AppStatus-Committed';
            default:
                return 'AppStatusCombined';
        }
    };
    const valueGetter_AppStatusCombined = (params) => {
        // Returns the text for filtering and sorting.
        const usacAppStatus4Text = params.row.usacAppStatus4Text;
        const ercAppStatusText = valueGetter_ECRAppStatusText(params);
        const reviewStatusText = params.row.reviewStatusText;
        let text = '';
        if (usacAppStatus4Text === 'Certified') {
            // Display only "Certified".
            text = 'Certified';
        } else {
            if (ercAppStatusText !== 'Unknown' && ercAppStatusText !== '') {
                // Display USAC status and ERC status.
                text = usacAppStatus4Text + ' (' + ercAppStatusText + ')';
            } else {
                // Display USAC status.
                text = usacAppStatus4Text;
            }
        }
        if (reviewStatusText) {
            text += ' ' + reviewStatusText;
        }
        return text;
    };
    const renderCell_AppStatusCombined = (params) => {
        // Returns the React node for display.
        const usacAppStatus4Text = params.row.usacAppStatus4Text;
        const ercAppStatusText = valueGetter_ECRAppStatusText(params);
        const reviewStatusText = params.row.reviewStatusText;
        let ascReactNode1 = null;
        if (usacAppStatus4Text === 'Certified') {
            // Display only "Certified".
            ascReactNode1 = <div className='USACAppStatus'>Certified</div>;
        } else {
            if (ercAppStatusText !== 'Unknown' && ercAppStatusText !== '') {
                // Display USAC status and ERC status.
                ascReactNode1 = <div className='AppStatuses'>{usacAppStatus4Text + ' (' + ercAppStatusText + ')'}</div>;
            } else {
                // Display USAC status.
                ascReactNode1 = <div className='USACAppStatus'>{usacAppStatus4Text}</div>;
            }
        }
        let ascReactNode2 = null;
        if (reviewStatusText) {
            ascReactNode2 = <div className='ReviewStatus'>{reviewStatusText}</div>;
        }
        const tooltipText = _createAppStatusTooltipText(params);
        return (
            <Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipText}</span>}
                key={'appStatusCombinedTooltip-' + params.row.form471AppNum}
            >
                <div>
                    {ascReactNode1}
                    {ascReactNode2}
                </div>
            </Tooltip>
        );
    };
    const _createAppStatusTooltipText = (params) => {
        var t = 'USAC App Status:  ' + params.row.usacAppStatus4Text + '\r\n';
        t += 'ERC App Status:  ' + valueGetter_ECRAppStatusText(params) + '\r\n';
        if (params.row.reviewStatusText) {
            t += 'Review Status:  ' + params.row.reviewStatusText + '\r\n';
        }
        return t;
    };
    //--------------------------------------------------

    //============================================================
    //      DataGrid - "linked columns"
    //============================================================
    //const qaSubmitterSelectElementRef = React.useRef(null);
    const checkIfEmptyAndFillQAFields = (params) => {
        // User has changed the "ERC App Status" dropdown to "Ready for QA" (20)...
        // If empty, set "QA Submitter", "QA Date Submitted", and "QA Date Needed" to defaults.
        if (!params) {
            return;
        }

        //*****  Determine `bSetToDefaults`.  *****
        let bSetToDefaults = false;
        if (!params.row.qa_form471app) {
            //console.log('qa_form471app is empty.');
            bSetToDefaults = true;
        } else {
            let ExistingSubmitterUserID = params.row.qa_form471app.qa_submitter_user_id;
            let ExistingDateSubmitted = params.row.qa_form471app.qa_date_submitted;
            let ExistingDateNeeded = params.row.qa_form471app.qa_date_needed;
            //console.log('ExistingSubmitterUserID = ', ExistingSubmitterUserID);
            //console.log('ExistingDateSubmitted = ', ExistingDateSubmitted);
            //console.log('ExistingDateNeeded = ', ExistingDateNeeded);
            if (ExistingSubmitterUserID == null && ExistingDateSubmitted == null && ExistingDateNeeded == null) {
                //console.log('qa_form471app is not empty but QA Submitter, QA Date Submitted, and QA Date Needed are empty.');
                bSetToDefaults = true;
            }
        }
        //bSetToDefaults = true;  // FOR TESTING

        if (bSetToDefaults) {
            //*****  Set "QA Submitter", "QA Date Submitted", and "QA Date Needed" to defaults.  *****
            //console.log('NO EXISTING VALUES. Setting "QA Submitter", "QA Date Submitted", and "QA Date Needed" to defaults...');

            let newQASubmitterUserId = -1;
            if (
                params.row.form471app.application_owner_user_id !== null &&
                params.row.form471app.application_owner_user_id !== 0
            ) {
                // If "Application Owner" has been overriden, then use that value.
                newQASubmitterUserId = params.row.form471app.application_owner_user_id;
            } else {
                // If "Application Owner" is the default value..
                //console.log('[checkIfEmptyAndFillQAFields] Using params.row.resolvedApplicationOwner.id.  params.row.resolvedApplicationOwner.id = ' + params.row.resolvedApplicationOwner.id);
                newQASubmitterUserId = params.row.resolvedApplicationOwner.id;
            }
            //console.log('[checkIfEmptyAndFillQAFields] newQASubmitterUserId = ' + newQASubmitterUserId);

            let bOnQASubmitterDropdownList = false;
            let ddoption = qaSubmitterEditOptions.find((element) => element.value === newQASubmitterUserId);
            if (ddoption) {
                bOnQASubmitterDropdownList = true;
            }

            if (bOnQASubmitterDropdownList) {
                apiRef.current.setEditCellValue({
                    id: params.id,
                    field: 'QASubmitterUserID',
                    value: newQASubmitterUserId,
                });
            }

            let today = create_todayJSDate();
            let defaultQADateNeeded = create_todayJSDate();
            defaultQADateNeeded.setDate(defaultQADateNeeded.getDate() + 2); // TODO: Add 2 weekday/business days not calendar days.

            // console.log('[checkIfEmptyAndFillQAFields]  today.toLocaleDateString() = ' + today.toLocaleDateString());
            // console.log('[checkIfEmptyAndFillQAFields]  defaultQADateNeeded.toLocaleDateString() = ' + defaultQADateNeeded.toLocaleDateString());
            // console.log('[checkIfEmptyAndFillQAFields]  today.toISOString() = ' + today.toISOString());
            // console.log('[checkIfEmptyAndFillQAFields]  defaultQADateNeeded.toISOString() = ' + defaultQADateNeeded.toISOString());

            apiRef.current.setEditCellValue({
                id: params.id,
                field: 'QADateSubmitted',
                value: today,
            });
            // apiRef.current.setEditCellValue({
            //     id: params.id,
            //     field: 'QADateNeeded',
            //     value: defaultQADateNeeded,
            // });

            //*****  Update the "QA Submitter" dropdown (MUI Select) to display the new value.  *****
            // 2024-01-05 note by Bill: This is happening automatically in Form 470 Applications but not in this page. Why?
            // TODO
            // console.log('qaSubmitterSelectElementRef.current = ', qaSubmitterSelectElementRef.current); // <div class="MuiInputBase-root MuiOut…put-root-MuiSelect-root">
            // console.log("qaSubmitterSelectElementRef.current.value = ", qaSubmitterSelectElementRef.current.value);     // undefined
            // console.log("qaSubmitterSelectElementRef.current.labelId = ", qaSubmitterSelectElementRef.current.labelId);     // undefined
            // console.log("qaSubmitterSelectElementRef.current.input = ", qaSubmitterSelectElementRef.current.input);     // undefined
        }
    };

    // Updates the old widths with the values of the new "current" widths if present, or just uses the original width
    const dynamicColumnWidthWrapper = (original_cols, current_cols) => {
        return original_cols.map((col) => ({
            ...col,
            width: current_cols[col.field] || col.width,
        }));
    };

    // The original, untouched column definitions
    const originalColumns = [
        {
            // Actions (Details and Edit page links)
            headerName: 'Actions',
            field: 'actions',
            type: 'actions',
            getActions: (params) => {
                // params = GridRowParams (columns, id, row)
                // console.log('[originalColumns][getActions] params:', params);
                // console.log('[originalColumns][getActions] rowModesModel:', rowModesModel);

                const appdetailsbuttonkey =
                    'appDetailsButton-' + params.row.form470AppNum + '-' + params.row.form470AppIncrement;
                const appeditbuttonkey =
                    'appEditButton-' + params.row.form470AppNum + '-' + params.row.form470AppIncrement;
                const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
                const apphistorybuttonkey =
                    'appHistoryButton-' + params.row.form470AppNum + '-' + params.row.form470AppIncrement;

                if (isInEditMode) {
                    return [
                        <Tooltip title='Save' key={`save-${params.id}`}>
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label='Save'
                                onClick={handleSaveIconClick(params.id)}
                                // color='inherit'
                                style={{ color: '#4CAF50' }}
                            />
                        </Tooltip>,
                        <Tooltip title='Cancel' key={`cancel-${params.id}`}>
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label='Cancel'
                                onClick={handleCancelIconClick(params.id)}
                                // color='inherit'
                                style={{ color: 'red' }}
                            />
                        </Tooltip>,
                    ];
                }

                return [
                    <Tooltip title='History' key={apphistorybuttonkey}>
                        <GridActionsCellItem
                            icon={<HourglassBottomIcon />}
                            label='History'
                            color='info'
                            aria-label='history'
                            onClick={() => {
                                setActiveHistoryForm({
                                    formNumber: params.row.form471AppNum,
                                });
                                setHistoryModalOpen(true);
                            }}
                            sx={{ margin: '0 -4px' }}
                        ></GridActionsCellItem>
                    </Tooltip>,
                    <Tooltip title='Details' key={appdetailsbuttonkey}>
                        <GridActionsCellItem
                            icon={<ArticleIcon />}
                            label='Details'
                            color='info'
                            aria-label='details'
                            onClick={() => handleDetailsButtonClick(params.row.form471AppNum)}
                            sx={{ margin: '0 -4px' }}
                        ></GridActionsCellItem>
                    </Tooltip>,
                    <Tooltip title='Edit' key={appeditbuttonkey}>
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label='Edit'
                            color='primary'
                            aria-label='edit'
                            onClick={() => handleEditButtonClick(params.row.form471AppNum)}
                            sx={{ margin: '0 -4px' }}
                        ></GridActionsCellItem>
                    </Tooltip>,
                ];
            },
            width: 90,
            filterable: false,
            sortable: false,
        },
        {
            headerName: 'Funding Year',
            field: 'fundingYear',
            ...numberAndNoCommas,
            width: 100,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Funding
                    <br />
                    Year
                </Box>
            ),
        },
        {
            headerName: 'Filing Window',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'FilingWindowID',
            valueGetter: valueGetter_getFilingWindowName, // form471app.form471_filing_window.filing_window_name
            valueSetter: valueSetter_setFilingWindowID,
            editable: true,
            renderEditCell: (params) => {
                let selectedid = params.row.form471app.filing_window_id || 0;
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={filingWindowEditOptions}
                    />
                );
            },
            width: 150,
        },
        {
            headerName: 'Funding Program',
            field: 'fundingProgram',
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Funding
                    <br />
                    Program
                </Box>
            ),
            width: 150,
        },
        {
            headerName: 'BEN',
            field: 'ben',
            ...numberAndNoCommas,
            headerAlign: 'left',
            renderCell: (params) => (
                <Tooltip title='View Client' key={'viewClientBENLinkTooltip-' + params.row.form471AppNum}>
                    <Link
                        href={'../../client-list/details/' + params.row.client_id + '?ben=' + params.row.ben}
                        target='_blank'
                        rel='noopener'
                    >
                        {params.row.ben}
                    </Link>
                </Tooltip>
            ),
            width: 120,
        },
        {
            headerName: 'Applicant',
            field: 'clientName',
            width: 200,
        },
        {
            headerName: 'State',
            field: 'state',
            width: 100,
        },
        {
            headerName: 'Primary Contact',
            field: 'primary_contact',
            valueGetter: getPrimaryContactName,
            renderCell: (params) => {
                let user = params.row.primary_contact;
                let chipkey = 'pc-' + params.row.form471AppNum + '-' + user.first_name + user.last_name;
                let chiplabel = user.first_name + ' ' + user.last_name;
                return <Chip key={chipkey} label={chiplabel} />;
            },
            width: 150,
        },
        {
            headerName: 'Secondary Contacts',
            field: 'SecondaryContacts',
            valueGetter: getSecondaryContactNames,
            renderCell: (params) => {
                return createUserChips(params.row.secondary_contacts, params.row.form471AppNum, 'sc');
            },
            width: 300,
        },
        {
            headerName: 'Application Owner',
            headerClassName: 'Editable-NotRequired',
            cellClassName: (params) => {
                if (params.row.form471app.application_owner_user_id !== null) {
                    return 'InlineEditable ApplicationOwner-Overridden';
                }
                return 'InlineEditable ApplicationOwner-Default';
            },
            field: 'ApplicationOwnerUserID',
            type: 'string',
            editable: true,
            valueGetter: (params) => {
                //~ Display the "resolved" Application Owner.  By default the Application Owner is the Primary Contact.
                return getUserDisplayName(params.row.resolvedApplicationOwner);
            },
            valueSetter: (params) => {
                //~ Returning an `ApplicationOwnerUserID` for processRowUpdate to read (in newRow).
                //~ params.value is the employee name returned by valueGetter unless/until the user changes the dropdown.
                if (Number.isInteger(params.value)) {
                    //~ User has changed the Application Owner edit dropdown. params.value has changed from employee name text to a userid integer.
                    //console.log('[valueSetter] params.value has changed from employee name text to a userid integer.  params.value = ' + params.value);
                    let ApplicationOwnerUserID = params.value;
                    return { ...params.row, ApplicationOwnerUserID };
                } else {
                    let ApplicationOwnerUserID = params.row.form471app.application_owner_user_id || 0;
                    return { ...params.row, ApplicationOwnerUserID };
                }
            },
            renderEditCell: (params) => {
                //console.log('[renderEditCell] params.row = ', params.row);
                //console.log('[renderEditCell] params.field = ' + params.field);
                let selectedid = params.row.form471app.application_owner_user_id || 0;
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={applicationOwnerEditOptions}
                    />
                );
            },
            width: 200,
        },
        {
            headerName: 'Task Owner',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable TaskOwner',
            field: 'TaskOwnerUserID',
            valueGetter: valueGetter_getTaskOwnerNames,
            valueSetter: valueSetter_setTaskOwnerUserID,
            renderCell: (params) => {
                return createUserChips(params.row.form471app.task_users, params.row.form471AppNum, 'to');
            },
            editable: true,
            renderEditCell: (params) => {
                let selectedid = 0;
                if (params.row.form471app.task_users != null && params.row.form471app.task_users.length > 0) {
                    selectedid = params.row.form471app.task_users[0].id;
                }
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={taskOwnerEditOptions}
                    />
                );
            },
            width: 200,
        },
        {
            headerName: 'F471 Application Number',
            field: 'form471AppNum',
            ...numberAndNoCommas,
            headerAlign: 'left',
            renderCell: (params) => {
                if (!params.row.epcURL) {
                    return params.row.form471AppNum;
                }
                return (
                    <Tooltip title='Application page in EPC' key={'epclink-' + params.row.form471AppNum}>
                        <Link className='ExternalLink' href={params.row.epcURL} target='_blank' rel='noopener'>
                            {params.row.form471AppNum}
                        </Link>
                    </Tooltip>
                );
            },
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    F471 Application
                    <br />
                    Number
                </Box>
            ),
            width: 150,
        },
        {
            headerName: 'Nickname',
            field: 'nickname',
            width: 200,
        },
        {
            headerName: 'ERC App Status',
            headerClassName: 'Editable-NotRequired',
            cellClassName: cellClassName_ERCAppStatus,
            field: 'ERCAppStatusID',
            valueGetter: valueGetter_ECRAppStatusText, // form471app.erc_form471_app_status.status_text
            valueFormatter: (params) => {
                //console.log(params.field + " valueFormatter -- params.value = ", params.value);
                if (params.value == null || params.value === '') {
                    return '-';
                }
                return params.value;
            },
            editable: true,
            renderEditCell: (params) => {
                const selectedid = params.row.form471app.erc_form471_app_status_id || 0;
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={ercAppStatusEditOptions}
                    />
                );
            },
            valueParser: (value, params) => {
                //console.log(params.field + " valueParser -- value = ", value);
                //console.log(params.field + " valueParser -- params = ", params);
                //console.log(params.field + " valueParser -- params.row = ", params.row);

                if (value === 20) {
                    // User has changed the "ERC App Status" dropdown to "Ready for QA" (20)...
                    // If empty, set "QA Submitter", "QA Date Submitted", and "QA Date Needed" to defaults.
                    checkIfEmptyAndFillQAFields(params);
                }

                return value;
            },
            valueSetter: valueSetter_ERCAppStatusID,
            width: 200,
        },
        {
            headerName: 'Notes',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: '_form471AppNotes',
            minPopupWidth: 500,
            valueGetter: getForm471AppNotes, // form471app.form471_app_notes
            // ORIGINAL RENDERCELL
            // renderCell: (params) => (
            //     <NotesCellDisplay
            //         notes={params.value}
            //         minPopupWidth={500}
            //         computedWidth={params.colDef.computedWidth}
            //         rowHeightPref={rowHeightPref}
            //     />
            // ),

            // NEW RENDERCELL
            renderCell: (params) => {
                return (
                    <NotesCellDisplay
                        notes={params.value}
                        noteIsEditable={true}
                        minPopupWidth={500}
                        computedWidth={params.colDef.computedWidth}
                        rowHeightPref={rowHeightPref}
                        onEdit={() =>
                            handleEditApplicationNotes(
                                params.id,
                                params.field,
                                params.value,
                                params['row']['ben'],
                                params['row']['nickname']
                            )
                        }
                        isInViewMode={apiRef.current.getRowMode(params.id) === 'view'}
                    />
                );
            },
            renderEditCell: (params) => <InlineEditTextarea1 {...params} />,
            width: 300,
            editable: false,
        },
        {
            headerName: 'QA Submitter',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'QASubmitterUserID',
            valueGetter: valueGetter_getQASubmitterName,
            valueSetter: valueSetter_setQASubmitterUserID,
            editable: true,
            renderEditCell: (params) => {
                let selectedid = 0;
                if (params.row.qa_form471app != null) {
                    selectedid = params.row.qa_form471app.qa_submitter_user_id || 0;
                }
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={qaSubmitterEditOptions}
                        //selectElementRef={qaSubmitterSelectElementRef}
                    />
                );
            },
            width: 150,
        },
        {
            headerName: 'QA Date Submitted',
            renderHeader: (params) => renderMultilineHeader(params, 'QA Date', 'Submitted'),
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'QADateSubmitted',
            type: 'date',
            valueGetter: (params) => {
                if (!params.row.qa_form471app || params.row.qa_form471app.qa_date_submitted === null) {
                    return null;
                }
                // Expected Input: The date data coming through the API is a string in the format "yyyy-mm-dd".
                // Output: It is converted to a JavaScript Date object (that is adjusted as per the local timezone offset).
                // It needs to be a Date object for display, filtering, and sorting.
                // console.log("[QA Date Submitted, valueGetter]  params.row.qa_form471app.qa_date_submitted = " + params.row.qa_form471app.qa_date_submitted);
                return convert_yyyymmddString_to_JSDate(params.row.qa_form471app.qa_date_submitted);
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    // Field is empty or has been emptied/cleared.
                    return { ...params.row, QADateSubmitted: '' };
                }
                if (!(params.value instanceof Date)) {
                    // Input check
                    console.error('Expected params.value to be a JavaScript Date.');
                    return { ...params.row };
                }
                // Expected Input: If not null, params.value should be a JavaScript Date object.
                // Output: A date string in the format "yyyy-mm-dd" is returned.
                // console.log("~[QA Date Submitted, valueSetter]  params.value = " + params.value);
                let ymdstring = convert_JSDate_to_yyyymmddString(params.value);
                return { ...params.row, QADateSubmitted: ymdstring };
            },
            editable: true,
            width: 150,
        },
        {
            headerName: 'QA Date Needed',
            renderHeader: (params) => renderMultilineHeader(params, 'QA Date', 'Needed'),
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'QADateNeeded',
            type: 'date',
            valueGetter: (params) => {
                if (!params.row.qa_form471app || params.row.qa_form471app.qa_date_needed === null) {
                    return null;
                }
                // Expected Input: The date data coming through the API is a string in the format "yyyy-mm-dd".
                // Output: It is converted to a JavaScript Date object (that is adjusted as per the local timezone offset).
                // It needs to be a Date object for display, filtering, and sorting.
                return convert_yyyymmddString_to_JSDate(params.row.qa_form471app.qa_date_needed);
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    // Field is empty or has been emptied/cleared.
                    return { ...params.row, QADateNeeded: '' };
                }
                if (!(params.value instanceof Date)) {
                    // Input check
                    console.error('Expected params.value to be a JavaScript Date.');
                    return { ...params.row };
                }
                // Expected Input: If not null, params.value should be a JavaScript Date object.
                // Output: A date string in the format "yyyy-mm-dd" is returned.
                let ymdstring = convert_JSDate_to_yyyymmddString(params.value);
                return { ...params.row, QADateNeeded: ymdstring };
            },
            editable: true,
            width: 150,
        },
        {
            headerName: 'QA Estimated Funding Request',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'QAEstimatedFundingRequest',
            editable: true,
            valueGetter: valueGetter_getQAEstimatedFundingRequest,
            renderEditCell: (params) => {
                let selected = '';
                if (params.row.qa_form471app != null) {
                    selected = params.row.qa_form471app.qa_estimated_funding_request || '';
                }
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selected}
                        field={params.field}
                        dropDownOptions={qaEstimatedFundingRequestEditOptions}
                    />
                );
            },
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    QA Estimated
                    <br />
                    Funding Request
                </Box>
            ),
            width: 150,
        },
        {
            headerName: 'QA Status',
            headerClassName: 'Editable-NotRequired',
            cellClassName: cellClassName_QAReviewStatus,
            field: 'QAReviewStatusID',
            valueGetter: valueGetter_getQAReviewStatusText,
            valueFormatter: (params) => {
                //console.log(params.field + " valueFormatter -- params.value = ", params.value);
                if (params.value == null || params.value === '') {
                    return '-';
                }
                return params.value;
            },
            editable: true,
            renderEditCell: (params) => {
                let selectedid = 0;
                if (params.row.qa_form471app != null) {
                    selectedid = params.row.qa_form471app.qa_review_status_id || 0;
                }
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={qaReviewStatusEditOptions}
                    />
                );
            },
            valueSetter: valueSetter_setQAReviewStatusID,
            width: 200,
        },
        {
            headerName: 'QA Reviewer',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'QAReviewerUserID',
            valueGetter: valueGetter_getQAReviewerName,
            valueSetter: valueSetter_setQAReviewerUserID,
            editable: true,
            renderEditCell: (params) => {
                let selectedid = 0;
                if (params.row.qa_form471app != null) {
                    selectedid = params.row.qa_form471app.qa_review_user_id || 0;
                }
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={qaReviewerEditOptions}
                    />
                );
            },
            width: 150,
        },
        {
            headerName: 'QA Notes',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: '_qaNotes', // qa_form471app.qa_notes
            valueGetter: valueGetter_getQANotes,
            // ORIGINAL RENDERCELL
            // renderCell: (params) => (
            //     <NotesCellDisplay
            //         notes={params.value}
            //         minPopupWidth={500}
            //         computedWidth={params.colDef.computedWidth}
            //         rowHeightPref={rowHeightPref}
            //     />
            // ),

            // NEW RENDERCELL
            renderCell: (params) => {
                // console.log('QA Notes params: ', params);
                return (
                    <NotesCellDisplay
                        notes={params.value}
                        noteIsEditable={true}
                        minPopupWidth={500}
                        computedWidth={params.colDef.computedWidth}
                        rowHeightPref={rowHeightPref}
                        onEdit={() =>
                            handleEditQANotes(params.id, 'qa_notes', params.value, params.row.ben, params.row.nickname)
                        }
                        isInViewMode={apiRef.current.getRowMode(params.id) === 'view'}
                    />
                );
            },
            editable: false,
            width: 300,
            renderEditCell: (params) => <InlineEditTextarea1 {...params} />,
        },
        {
            headerName: 'Date Sent to Client to Certify',
            renderHeader: (params) => renderMultilineHeader(params, 'Date Sent to', 'Client to Certify'),
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            field: 'DateSentToClientToCertify',
            type: 'date',
            valueGetter: (params) => {
                if (!params.row.form471app || params.row.form471app.date_sent_to_client_to_certify === null) {
                    return null;
                }
                // Expected Input: The date data coming through the API is a string in the format "yyyy-mm-dd".
                // Output: It is converted to a JavaScript Date object (that is adjusted as per the local timezone offset).
                // It needs to be a Date object for display, filtering, and sorting.
                return convert_yyyymmddString_to_JSDate(params.row.form471app.date_sent_to_client_to_certify);
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    // Field is empty or has been emptied/cleared.
                    return { ...params.row, DateSentToClientToCertify: '' };
                }
                if (!(params.value instanceof Date)) {
                    // Input check
                    console.error('Expected params.value to be a JavaScript Date.');
                    return { ...params.row };
                }
                // Expected Input: If not null, params.value should be a JavaScript Date object.
                // Output: A date string in the format "yyyy-mm-dd" is returned.
                let ymdstring = convert_JSDate_to_yyyymmddString(params.value);
                return { ...params.row, DateSentToClientToCertify: ymdstring };
            },
            editable: true,
            width: 150,
        },
        {
            headerName: 'Date Certified',
            field: 'certifiedDate',
            type: 'date',
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }
                // Add 'Z' to the end. CompiledForm471UsacData.certified_date is a `timestamp` so we're getting datetime strings without a time zone indicator.
                // Examples: "2017-05-10T23:27:00", "2023-03-27T21:47:00"
                return new Date(params.value + 'Z');
            },
            renderCell: (params) => {
                // console.log('<Date Certified, renderCell> params.row.form471AppNum = ' + params.row.form471AppNum);
                // console.log('<Date Certified, renderCell> params.row.certifiedDate = ' + params.row.certifiedDate + ' | typeof = ' + (typeof params.row.certifiedDate));  // typeof = string
                // console.log('<Date Certified, renderCell> params.value = ' + params.value + ' | typeof = ' + (typeof params.value));  // typeof = object   From valueGetter.
                // params.value is from valueGetter and is expected to be either null or a JavaScript Date object.
                if (params.value === null) {
                    return null;
                }
                return renderDateTime2(params.value, false);
            },
            width: 200,
        },
        {
            headerName: 'USAC App Status',
            cellClassName: cellClassName_USACAppStatus,
            field: 'usacAppStatus4Text',
            width: 150,
        },
        {
            headerName: 'Window Status',
            field: 'windowStatusText',
            width: 150,
        },
        {
            headerName: 'App Status (combined)',
            cellClassName: cellClassName_AppStatusCombined,
            field: '_appStatusCombined',
            valueGetter: valueGetter_AppStatusCombined,
            renderCell: renderCell_AppStatusCombined,
            width: 250,
        },
        {
            headerName: 'PIA Review Status',
            field: 'reviewStatusText',
            width: 150,
        },
        {
            headerName: 'Last Updated On',
            field: '_userEnteredFieldUpdatedTimestamp',
            type: 'dateTime',
            valueGetter: (params) => {
                // Input example: "2024-02-09T20:03:56.983869+00:00" or null
                if (!params.row.form471app.user_entered_field_updated_timestamp) {
                    return null;
                }
                return new Date(params.row.form471app.user_entered_field_updated_timestamp);
            },
            renderCell: (params) => {
                // params.value is from valueGetter and is expected to be either null or a JavaScript Date object.
                if (params.value === null) {
                    return null;
                }
                return renderDateTime2(params.value, true);
            },
            width: 200,
        },
        {
            headerName: 'Total Pre-Discounted Amount',
            field: 'totalPreDiscountedAmount',
            ...usdPrice,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Total Pre-Discounted
                    <br />
                    Amount
                </Box>
            ),
            width: 150,
        },
        {
            headerName: 'Total Post-Discounted Amount',
            field: 'totalPostDiscountedAmount',
            ...usdPrice,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Total Post-Discounted
                    <br />
                    Amount
                </Box>
            ),
            width: 150,
        },
        {
            headerName: 'Is a Client',
            field: 'isAClient',
            type: 'boolean',
            renderCell: (params) => <>{params.value === true ? 'Yes' : params.value === false ? 'No' : ''}</>,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Is a
                    <br />
                    Client
                </Box>
            ),
            width: 100,
        },
        {
            headerName: 'Applicant Cohorts',
            field: 'ApplicantCohorts',
            valueGetter: getApplicantCohortNames,
            renderCell: (params) => {
                return params.row.client_applicantcohorts.map((cac) => {
                    return (
                        <Chip
                            key={params.row.form471AppNum + '-' + cac.applicantcohort.applicantcohort_name}
                            label={cac.applicantcohort.applicantcohort_name}
                            sx={{ marginRight: '4px' }}
                        />
                    );
                });
            },
            width: 200,
        },
    ];

    const usacAppStatusOptions = [
        { text: 'Incomplete', value: 1 },
        { text: 'Certified', value: 2 },
        { text: 'Committed', value: 3 },
        { text: 'Cancelled', value: 4 },
    ];

    const windowStatusTextMSFilterOptions = [
        { text: 'In-Window', value: 1 },
        { text: 'Out-Of-Window', value: 2 },
    ];

    const newApplicationOwnerEditOptions =
        applicationOwnerEditOptions.filter((ea_object) => ea_object.value !== 0) || [];
    const newTaskOwnerEditOptions = taskOwnerEditOptions.filter((ea_object) => ea_object.value !== 0) || [];
    const newQAReviewerEditOptions = qaReviewerEditOptions.filter((ea_object) => ea_object.value !== 0) || [];
    const newQASubmitterEditOptions = qaSubmitterEditOptions.filter((ea_object) => ea_object.value !== 0) || [];

    const editOptions = {
        newApplicationOwnerEditOptions,
        applicantCohortMSFilterOptions,
        applicantMSFilterOptions,
        ercAppStatusEditOptions,
        filingWindowEditOptions,
        fundingProgramMSFilterOptions,
        qaEstimatedFundingRequestEditOptions,
        newQAReviewerEditOptions,
        piaReviewStatusMSFilterOptions,
        qaReviewStatusEditOptions,
        newQASubmitterEditOptions,
        stateMSFilterOptions,
        newTaskOwnerEditOptions,
        usacAppStatusOptions,
        windowStatusTextMSFilterOptions,
    };

    const editOptionsMapping = {
        // _appStatusCombined: '', // App Status Combined
        ApplicantCohorts: 'applicantCohortMSFilterOptions', // Applicant Cohorts
        clientName: 'applicantMSFilterOptions', // Applicant
        ApplicationOwnerUserID: 'newApplicationOwnerEditOptions', // Application Owner
        ERCAppStatusID: 'ercAppStatusEditOptions', // ERC App Status
        FilingWindowID: 'filingWindowEditOptions', // Filing Window
        fundingProgram: 'fundingProgramMSFilterOptions',
        QAEstimatedFundingRequest: 'qaEstimatedFundingRequestEditOptions',
        reviewStatusText: 'piaReviewStatusMSFilterOptions', // PIA Review Status
        QAReviewStatusID: 'qaReviewStatusEditOptions', // QA Status
        QAReviewerUserID: 'newQAReviewerEditOptions', // QA Reviewer
        QASubmitterUserID: 'newQASubmitterEditOptions', // QA Submitter
        state: 'stateMSFilterOptions', // State
        TaskOwnerUserID: 'newTaskOwnerEditOptions', // Task Owner

        // fundingProgram: '', // Funding Program, no already-defined MS options
        primary_contact: 'newApplicationOwnerEditOptions', // Primary Contacts
        SecondaryContacts: 'newApplicationOwnerEditOptions', // Secondary Contacts
        usacAppStatus4Text: 'usacAppStatusOptions', // USAC App Status
        windowStatusText: 'windowStatusTextMSFilterOptions', // Window Status, no already-defined MS options
    };

    // Setting the original/default widths of the columns
    const originalWidths = {};
    originalColumns.forEach((ea_col) => {
        originalWidths[ea_col.field] = ea_col.width;
    });
    originalColumnWidthSetter(JSON.stringify(originalWidths));
    originalColumnOrderSetter(JSON.stringify(originalColumns.map((ea_col) => ea_col.field)));

    // currentColumnWidths are the current column widths in storage
    let col_array = dynamicColumnWidthWrapper(
        // Uncomment for column-to-column filter comparisons
        // IsAfterXDaysWrapper(
        //     IsBeforeXDaysWrapper(
        //         IsNotAnyOfWrapper(
        //             IsAnyOfWrapper(
        //                 IsLessThanOrEqualToColumnWrapper(
        //                     IsLessThanColumnWrapper(
        //                         IsGreaterThanOrEqualToColumnWrapper(
        //                             IsGreaterThanColumnWrapper(
        //                                 IsNotEqualToColumnWrapper(
        //                                     IsEqualToColumnWrapper(
        //                                         doesNotContainWrapper(
        //                                             removeAnyBooleanFilterValueWrapper(originalColumns)
        //                                         )
        //                                     )
        //                                 )
        //                             )
        //                         )
        //                     )
        //                 ),
        //                 editOptions,
        //                 editOptionsMapping
        //             ),
        //             editOptions,
        //             editOptionsMapping
        //         )
        //     )
        // ),

        // IsNotAnyOfWrapper(
        //     IsAnyOfWrapper(
        //         doesNotContainWrapper(removeAnyBooleanFilterValueWrapper(originalColumns)),
        //         editOptions,
        //         editOptionsMapping
        //     ),
        //     editOptions,
        //     editOptionsMapping
        // ),

        IsNotAnyOfWrapper(
            IsAnyOfWrapper(
                IsLessThanOrEqualToColumnWrapper(
                    IsLessThanColumnWrapper(
                        IsGreaterThanOrEqualToColumnWrapper(
                            IsGreaterThanColumnWrapper(
                                IsNotEqualToColumnWrapper(
                                    IsEqualToColumnWrapper(
                                        doesNotContainWrapper(removeAnyBooleanFilterValueWrapper(originalColumns))
                                    )
                                )
                            )
                        )
                    )
                ),
                editOptions,
                editOptionsMapping
            ),
            editOptions,
            editOptionsMapping
        ),
        currentColumnWidths
    );

    //console.log('~~~ haveEditDropdownOptions = ' + haveEditDropdownOptions + ' | rowHeightPref = ' + rowHeightPref + ' | columnResetCount = ' + columnResetCount + '   ' + (new Date()).toISOString());
    //return col_array;

    return [
        col_array,
        <AppHistoryModal
            open={historyModalOpen}
            setHistoryModalOpen={setHistoryModalOpen}
            appNum={activeHistoryForm?.formNumber}
            applicationOwnerEditOptions={applicationOwnerEditOptions}
            allActiveUsers={allActiveUsers}
            ercAppStatusEditOptions={ercAppStatusEditOptions}
            qaReviewStatusEditOptions={qaReviewStatusEditOptions}
            qaSubmitterEditOptions={qaSubmitterEditOptions}
            taskOwnerEditOptions={taskOwnerEditOptions}
        />,
    ];
}
