import React from 'react';
import UserAPI from '../api/UserAPI.js';
import { useAuthContext } from '../useAuthContext';
import Box from '@mui/material/Box';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import NotificationTypes from '../components/NotificationTypes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const userAPI = new UserAPI();

function Notifications() {
    const auth = useAuthContext();
    const [notifications, setNotifications] = React.useState([]);
    const [expanded, setExpanded] = React.useState({});
    const [expandedDropdown, setExpandedDropdown] = React.useState(true);
    const [checkedNotifications, setCheckedNotifications] = React.useState({});
    const [checkedNotificationIds, setCheckedNotificationIds] = React.useState([]);

    React.useEffect(() => {
        const getNotifications = async () => {
            try {
                let notifications = await userAPI.GetNotificationsForUser(auth.cognitoID);
                console.log('Notifications: notifications =', notifications);

                let sortedNotifications = sortNotificationArray(notifications);
                setNotifications(sortedNotifications);

                const initialExpandedState = {}; // Object to hold each notification's independent expanded state (default true) id:true/false
                for (let i = 0; i < notifications.length; i++) {
                    initialExpandedState[notifications[i]['id']] = true;
                }
                // console.log('Notifications: This is the initialExpandedState =', initialExpandedState);
                setExpanded(initialExpandedState);
            } catch (error) {
                console.log('Error loading notifications');
                console.log(error);
            }
        };
        getNotifications();
    }, []);

    const handlePanelChange = (panelID) => (event, currentExpBool) => {
        setExpanded((prevExpandedObject) => ({ ...prevExpandedObject, [panelID]: currentExpBool })); // functional update syntax
    };

    const handleCarrotClick = (event, expansionStatus) => {
        setExpandedDropdown(expansionStatus);
    };

    function sortNotificationArray(unsorted) {
        unsorted.sort(function (a, b) {
            return new Date(b.created_on) - new Date(a.created_on);
        });
        return unsorted;
    }

    // For processing INDIVIDUAL notifications
    async function markAsReadUnreadOrDeleted(event, messageID, recipientID, readUnreadOrDeleted) {
        //console.log(event);
        event.stopPropagation();

        try {
            let notiInfo = {
                id: messageID,
                recipient_id: recipientID,
                read_or_deleted: readUnreadOrDeleted,
            };

            console.log(notiInfo);
            let response = await userAPI.MarkNotificationsAsX(notiInfo, '', '', '', 'selected', 'rud');
            // let response = await userAPI.MarkNotificationAsReadUnreadOrDeleted(notiInfo);
            console.log(response);

            let updatedNotifications = notifications.slice(); // Copy array
            let selectedNotificationIndex = updatedNotifications.findIndex((per_noti) => per_noti.id === response.id);

            if (readUnreadOrDeleted === 'read' || readUnreadOrDeleted === 'unread') {
                updatedNotifications[selectedNotificationIndex] = response;
                setNotifications(updatedNotifications);
            }

            if (readUnreadOrDeleted === 'deleted') {
                //toast.success('Message Deleted');
                updatedNotifications.splice(selectedNotificationIndex, 1);
                let sortedNotifications = sortNotificationArray(updatedNotifications);
                setNotifications(sortedNotifications);
            }
        } catch (error) {
            toast.error('Error updating notification.');
        }
    }
    // For processing ALL notifications
    async function markAllAsRead(event) {
        //console.log(event);
        event.stopPropagation();

        try {
            let response = await userAPI.MarkNotificationsAsX('', '', '', auth.cognitoID, 'all', 'r');
            //let response = await userAPI.MarkAllNotificationsAsRead(auth.cognitoID);
            console.log('MarkAllNotificationsAsRead response = ', response);
            if (!response) {
                toast.error('Error marking all notifications as read (response is empty).');
                return;
            }
            console.log('Number of notifications marked as read: ' + response.numUpdated);
            console.log('response.notifications.length = ' + response.notifications.length);
            console.log('response.notifications = ', response.notifications);
            console.log('typeof response.notifications = ', typeof response.notifications);
            let sortedNotifications = sortNotificationArray(response.notifications);
            setNotifications(sortedNotifications);
        } catch (error) {
            console.error('markAllAsRead error = ', error);
            toast.error('Error marking all notifications as read.');
        }
    }

    // For processing ALL SELECTED notifications as read
    function markAllSelectedAsReadIDs() {
        const markAsReadNotificationArray = [];
        for (let id of checkedNotificationIds) {
            const notification = notifications.find((noti) => noti.id === id);
            markAsReadNotificationArray.push({
                id: notification.id,
                recipient_id: notification.recipient_id,
            });
        }
        return markAsReadNotificationArray;
    }
    // For processing ALL SELECTED notifications as unread
    function markAllSelectedAsUnreadIDs() {
        const markAsUnreadNotificationArray = [];
        for (let id of checkedNotificationIds) {
            const notification = notifications.find((noti) => noti.id === id);
            markAsUnreadNotificationArray.push({
                id: notification.id,
                recipient_id: notification.recipient_id,
            });
        }
        return markAsUnreadNotificationArray;
    }
    // For processing ALL SELECTED notifications as deleted
    function markAllSelectedAsDeletedIDs() {
        const markAsDeletedNotificationArray = [];

        for (let id of checkedNotificationIds) {
            const notification = notifications.find((noti) => noti.id === id);
            markAsDeletedNotificationArray.push({
                id: notification.id,
                recipient_id: notification.recipient_id,
            });
        }
        return markAsDeletedNotificationArray;
    }
    async function markAllSelectedAsReadUnreadOrDeleted(
        cognitoID,
        event,
        markAsReadOrDeletedNotificationsArray,
        message
    ) {
        event.stopPropagation();

        try {
            console.log('markAsReadOrDeletedNotificationsArray', markAsReadOrDeletedNotificationsArray);

            let response = await userAPI.MarkNotificationsAsX(
                '',
                markAsReadOrDeletedNotificationsArray,
                message,
                cognitoID,
                'all',
                'rud'
            );
            /* let response = await userAPI.MarkAllSelectedNotificationsAsReadUnreadOrDeleted(
                cognitoID,
                markAsReadOrDeletedNotificationsArray,
                message
            ); */

            if (!response) {
                toast.error('Error with marking all selected notifications as read or deleted (response is empty).');
                return;
            }
            console.log('the response:', response);

            if (message === 'read all selected') {
                const updatedNotifications = notifications.map((per_noti) => {
                    if (checkedNotificationIds.includes(per_noti.id)) {
                        return { ...per_noti, read: true }; // update the read status to true
                    }
                    return per_noti;
                });
                let sortedNotifications = sortNotificationArray(updatedNotifications);
                setNotifications(sortedNotifications);
                setCheckedNotificationIds([]); // reset the checked notifications
            }

            if (message === 'unread all selected') {
                const updatedNotifications = notifications.map((per_noti) => {
                    if (checkedNotificationIds.includes(per_noti.id)) {
                        return { ...per_noti, read: false }; // update the read status to true
                    }
                    return per_noti;
                });
                let sortedNotifications = sortNotificationArray(updatedNotifications);
                setNotifications(sortedNotifications);
                setCheckedNotificationIds([]); // reset the checked notifications
            }

            if (message === 'delete all selected') {
                const updatedNotifications = notifications.filter(
                    (notification) => !checkedNotificationIds.includes(notification.id)
                );
                setNotifications(updatedNotifications);
                setCheckedNotificationIds([]); // reset the checked notifications
            }
        } catch (error) {
            console.error('MarkAllSelectedNotificationsAsReadUnreadOrDeleted error = ', error);
            toast.error('Error with marking all selected notifications.');
        }
    }

    return (
        <>
            <ToastContainer theme='colored' autoClose={false} closeOnClick />
            <Box>
                <Box display='flex' alignItems='center' justifyContent='flex-start' sx={{ marginBottom: '1rem' }}>
                    <Checkbox
                        checked={notifications.length > 0 && checkedNotificationIds.length === notifications.length}
                        indeterminate={
                            Object.values(checkedNotifications).some((val) => val === true) &&
                            !Object.values(checkedNotifications).every((val) => val === true)
                        }
                        onChange={() => {
                            if (checkedNotificationIds.length === notifications.length) {
                                setCheckedNotificationIds([]);
                            } else {
                                const allNotificationIds = notifications.map((noti) => noti.id);
                                setCheckedNotificationIds(allNotificationIds);
                            }
                        }}
                    />
                    <Typography variant='body1'>Select All</Typography>
                </Box>

                {notifications.map((per_noti) => {
                    let bgColor = per_noti.read ? 'lightgray' : 'white';
                    return (
                        <Accordion
                            key={per_noti.created_on}
                            expanded={expanded[per_noti.id] || false}
                            onChange={handlePanelChange(per_noti.id)}
                        >
                            <AccordionSummary sx={{ backgroundColor: bgColor }} expandIcon={<ExpandMoreIcon />}>
                                <Box
                                    display='flex'
                                    justifyContent='space-between'
                                    sx={{ width: '100% !important', alignItems: 'center' }}
                                >
                                    <Checkbox
                                        checked={checkedNotificationIds.includes(per_noti.id)}
                                        onChange={(e) => {
                                            const checked = e.target.checked;
                                            if (checked) {
                                                setCheckedNotificationIds((prev) => [...prev, per_noti.id]);
                                            } else {
                                                setCheckedNotificationIds((prev) =>
                                                    prev.filter((id) => id !== per_noti.id)
                                                );
                                            }
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation(); // stops the collapsing
                                        }}
                                    />
                                    <Typography variant='h4' fontWeight={per_noti.read ? 'normal' : '500'}>
                                        {per_noti.notification_subject}
                                    </Typography>
                                    <Box>
                                        {!per_noti.read && (
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                size='small'
                                                sx={{ marginRight: '16px' }}
                                                onClick={(event) =>
                                                    markAsReadUnreadOrDeleted(
                                                        event,
                                                        per_noti.id,
                                                        per_noti.recipient_id,
                                                        'read'
                                                    )
                                                }
                                            >
                                                Mark as Read
                                            </Button>
                                        )}

                                        <Button
                                            variant='contained'
                                            color='error'
                                            size='small'
                                            sx={{ marginRight: '16px' }}
                                            onClick={(event) =>
                                                markAsReadUnreadOrDeleted(
                                                    event,
                                                    per_noti.id,
                                                    per_noti.recipient_id,
                                                    'deleted'
                                                )
                                            }
                                        >
                                            Delete
                                        </Button>

                                        <Button
                                            variant='contained'
                                            color='primary'
                                            size='small'
                                            sx={{ marginRight: '16px' }}
                                            onClick={(event) =>
                                                markAsReadUnreadOrDeleted(
                                                    event,
                                                    per_noti.id,
                                                    per_noti.recipient_id,
                                                    'unread'
                                                )
                                            }
                                        >
                                            Mark as Unread
                                        </Button>
                                    </Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ paddingTop: '0px' }}>
                                <Typography
                                    sx={{
                                        fontStyle: 'italic',
                                        color: 'gray',
                                        fontWeight: 'light',
                                        fontSize: 12,
                                        marginBottom: '8px',
                                    }}
                                >
                                    Notification created on:{' '}
                                    {new Date(per_noti.created_on + '+00:00').toLocaleString('en-US', {
                                        timzeZone: 'EST',
                                    })}
                                    {/*TODO: The timezone thing above is a hack for functionality, need a long-term timezone handling solution*/}
                                </Typography>
                                <div dangerouslySetInnerHTML={{ __html: per_noti.notification_body }}></div>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}

                {notifications && notifications.length > 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '1.5rem',
                            marginBottom: '1.5rem',
                            marginLeft: '-200px',
                        }}
                    >
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            sx={{ marginRight: '20px' }}
                            onClick={(event) => {
                                const selectionArray = markAllSelectedAsReadIDs();
                                console.log('selectionArray', selectionArray);
                                markAllSelectedAsReadUnreadOrDeleted(
                                    auth.cognitoID,
                                    event,
                                    selectionArray,
                                    'read all selected'
                                );
                            }}
                            disabled={checkedNotificationIds.length === 0}
                        >
                            Mark Selected as Read
                        </Button>

                        <Button
                            variant='contained'
                            color='error'
                            size='small'
                            sx={{ marginRight: '20px' }}
                            onClick={(event) => {
                                const selectionArray = markAllSelectedAsDeletedIDs();
                                markAllSelectedAsReadUnreadOrDeleted(
                                    auth.cognitoID,
                                    event,
                                    selectionArray,
                                    'delete all selected'
                                );
                            }}
                            disabled={checkedNotificationIds.length === 0}
                        >
                            Delete Selected
                        </Button>

                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            sx={{ marginRight: '20px' }}
                            onClick={(event) => {
                                const selectionArray = markAllSelectedAsUnreadIDs();
                                console.log('selectionArray', selectionArray);
                                markAllSelectedAsReadUnreadOrDeleted(
                                    // change this
                                    auth.cognitoID,
                                    event,
                                    selectionArray,
                                    'unread all selected'
                                );
                            }}
                            disabled={checkedNotificationIds.length === 0}
                        >
                            Mark Selected as Unread
                        </Button>
                    </Box>
                )}
            </Box>

            <Box sx={{ marginTop: '3rem' }} id='NotificationTypes'>
                <Accordion onChange={handleCarrotClick}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h3'>Notification Types</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: '0px' }}>
                        <NotificationTypes />
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
}

export default Notifications;
