import { API } from 'aws-amplify';

function UserAPI() {
    async function addUserToTeam(userID, teamID, orgID) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/AddUserToTeam';
            const data = {
                body: { userID: userID, teamID: teamID, orgID: orgID },
            };
            const notiResponse = await API.post(apiName, path, data);
            return notiResponse;
        } catch (error) {
            console.error('error adding user to team: ', error);
        }
    }

    async function deleteAllNotifications(notiInfo, message) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/DeleteAllNotifications';
            const data = {
                body: { notiInfo: notiInfo, message: message },
            };
            const notiResponse = await API.post(apiName, path, data);
            return notiResponse;
        } catch (error) {
            console.error('error deleting notifications: ', error);
        }
    }

    async function deleteOrg(orgInfo) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Misc/DeleteOrg';
            const data = {
                body: orgInfo,
            };

            const orgResponse = await API.post(apiName, path, data);
            //console.log(orgResponse);
            return orgResponse;
        } catch (error) {
            console.error('error deleting organzation: ', error);
        }
    }

    async function deleteSavedSearch(searchID, appliesTo, userID) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/DeleteSavedSearch';
            const data = {
                body: { searchID: searchID, appliesTo: appliesTo, userID: userID },
            };
            const response = await API.del(apiName, path, data);
            return response;
        } catch (error) {
            console.error('Error deleting search: ', error);
        }
    }

    async function deleteSavedView(viewID, appliesTo, userID) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/DeleteSavedView';
            const data = {
                body: { viewID: viewID, appliesTo: appliesTo, userID: userID },
            };
            const response = await API.del(apiName, path, data);
            return response;
        } catch (error) {
            console.error('Error deleting view: ', error);
        }
    }

    async function deleteTeam(teamInfo) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/DeleteTeam';
            const data = {
                body: teamInfo,
            };

            const teamResponse = await API.post(apiName, path, data);
            //console.log(teamResponse);
            return teamResponse;
        } catch (error) {
            console.error('error deleting team: ', error);
        }
    }

    // TODO: Change to User parent path later
    async function deleteUser(userInfo) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Misc/DeleteUser';
            const data = {
                body: userInfo,
            };

            const userResponse = await API.post(apiName, path, data);
            //console.log(userResponse);
            return userResponse;
        } catch (error) {
            console.error('error deleting user: ', error);
        }
    }

    async function getEmployeeTypes() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetEmployeeTypes';

            const employeesResponse = await API.get(apiName, path);
            return employeesResponse;
        } catch (error) {
            console.error('error getting employee types: ', error);
        }
    }

    async function getEmployees() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetEmployees';

            const employeesResponse = await API.get(apiName, path);
            //console.log(employeesResponse);
            return employeesResponse;
        } catch (error) {
            console.error('error getting employees: ', error);
        }
    }

    async function getERateGeneralEmployees() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetERateGeneralEmployees';

            const generalEmployeesResponse = await API.get(apiName, path);
            //console.log(generalEmployeesResponse);
            return generalEmployeesResponse;
        } catch (error) {
            console.error('error getting E-Rate General Employees: ', error);
        }
    }

    async function getNotificationCountForUser(cognitoID) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetNotificationCountForUser';
            const queryStringParameters = {
                queryStringParameters: { cognito_id: cognitoID },
            };
            const countResponse = await API.get(apiName, path, queryStringParameters);
            return countResponse;
        } catch (error) {
            console.error('error getting notification count: ', error);
            return false;
        }
    }

    async function getNotificationsForUser(cognitoID) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetNotificationsForUser';
            const queryStringParameters = {
                queryStringParameters: { cognito_id: cognitoID },
            };
            const notificationResponse = await API.get(apiName, path, queryStringParameters);
            return notificationResponse;
        } catch (error) {
            console.error('error getting notifications: ', error);
            return false;
        }
    }

    async function getNotificationTypes() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetNotificationTypes';
            const typeResponse = await API.get(apiName, path);
            return typeResponse;
        } catch (error) {
            console.error('error getting notification types: ', error);
        }
    }

    async function getOrgs() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Misc/GetOrgs';

            const orgsResponse = await API.get(apiName, path);
            //console.log(orgsResponse);
            return orgsResponse;
        } catch (error) {
            console.error('error getting orgs: ', error);
        }
    }

    async function getSavedSearches(appliesTo) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetSavedSearches';
            const queryStringParameters = {
                queryStringParameters: { appliesTo: appliesTo },
            };

            const savedSearchResponse = await API.get(apiName, path, queryStringParameters);
            return savedSearchResponse;
        } catch (error) {
            console.error('error getting saved search: ', error);
        }
    }

    async function getSavedViews(appliesTo) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetSavedViews';
            const queryStringParameters = {
                queryStringParameters: { appliesTo: appliesTo },
            };

            const savedSearchResponse = await API.get(apiName, path, queryStringParameters);
            return savedSearchResponse;
        } catch (error) {
            console.error('error getting saved view: ', error);
        }
    }

    async function getTeamMembers(orgID, teamID) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Misc/GetTeamMembers';
            const data = {
                queryStringParameters: { orgID: orgID, teamID: teamID },
            };

            const teamsResponse = await API.get(apiName, path, data);
            return teamsResponse;
        } catch (error) {
            console.error('error getting team members: ', error);
        }
    }

    async function getTeams() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Misc/GetTeams';

            const teamsResponse = await API.get(apiName, path);
            //console.log(teamsResponse);
            return teamsResponse;
        } catch (error) {
            console.error('error getting teams: ', error);
        }
    }

    async function getTeamsByOrgID(orgID) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Misc/GetTeamsByOrgID';
            const data = {
                queryStringParameters: { orgID: orgID },
            };

            const teamsResponse = await API.get(apiName, path, data);
            return teamsResponse;
        } catch (error) {
            console.error('error getting teams by orgID: ', error);
        }
    }

    async function getUser() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetUserOrUsers/GetUser';

            const userResponse = await API.get(apiName, path);
            //console.log(userResponse);
            return userResponse;
        } catch (error) {
            console.error('error getting user: ', error);
        }
    }

    async function getUserOrUsers() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetUserOrUsers';

            const userResponse = await API.get(apiName, path);
            //console.log(userResponse);
            return userResponse;
        } catch (error) {
            console.error('error getting user: ', error);
        }
    }

    async function getUserRoles() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetUserRoles';

            const rolesReponse = await API.get(apiName, path);
            //console.log(rolesReponse);
            return rolesReponse;
        } catch (error) {
            console.error('error getting roles: ', error);
        }
    }

    async function getUsers() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetUserOrUsers/GetUsers';

            const usersResponse = await API.get(apiName, path);
            //console.log(usersResponse);
            return usersResponse;
        } catch (error) {
            console.error('error getting users: ', error);
        }
    }

    async function isAdmin(cognitoID) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetIsUserAdmin';
            const queryStringParameters = {
                queryStringParameters: { cognito_id: cognitoID },
            };

            const adminResponse = await API.get(apiName, path, queryStringParameters);
            return adminResponse;
        } catch (error) {
            console.error('error getting admin status: ', error);
            return false;
        }
    }

    // (markAsReadUnreadOrDeleted only, markAllSelectedAsReadUnreadOrDeleted's array only, markAllSelectedAsReadUnreadOrDeleted's message only,)
    async function markNotificationsAsX(notiInfo, selectedData, message, cognitoID, scope, action) {
        // scope: all or selected
        // action: read (r), unread (u), deleted (d)
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/MarkNotificationsAsX';
            const data = {
                body: {
                    notiInfo: notiInfo,
                    selectedData: selectedData,
                    message: message,
                    cognito_id: cognitoID,
                    scope: scope,
                    action: action,
                },
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('Error collapsing notifications: ', error);
        }
    }

    async function markAllNotificationsAsRead(cognitoID) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/MarkAllNotificationsAsRead';
            const data = {
                body: { cognito_id: cognitoID },
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('Error marking all notifications as read: ', error);
        }
    }

    async function markAllSelectedNotificationsAsReadUnreadOrDeleted(cognitoID, selectedData, message) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/MarkAllSelectedNotificationsAsReadUnreadOrDeleted';
            const data = {
                body: { cognito_id: cognitoID, selectedData: selectedData, message: message },
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('Error marking all selected notifications as read, unread, or deleted: ', error);
        }
    }

    async function markNotificationAsReadUnreadOrDeleted(notiInfo) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/MarkNotificationAsReadUnreadOrDeleted';
            const data = {
                body: notiInfo,
            };
            const notiResponse = await API.post(apiName, path, data);
            return notiResponse;
        } catch (error) {
            console.error('error updating notification: ', error);
        }
    }

    async function removeUserFromTeam(userID, teamID, orgID) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/RemoveUserFromTeam';
            const data = {
                body: { userID: userID, teamID: teamID, orgID: orgID },
            };
            const notiResponse = await API.del(apiName, path, data);
            return notiResponse;
        } catch (error) {
            console.error('error removing user: ', error);
        }
    }

    async function saveOrg(orgInfo) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Misc/SaveOrg';
            const data = {
                body: orgInfo,
            };

            const orgResponse = await API.post(apiName, path, data);
            //console.log(orgResponse);
            return orgResponse;
        } catch (error) {
            console.error('error saving org: ', error);
        }
    }

    async function saveSearch(userID, message, appliesTo, savedSearch) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/SaveSearch';
            const data = {
                body: { userID: userID, message: message, applies_to: appliesTo, savedSearch: savedSearch },
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('Error saving search: ', error);
        }
    }

    async function saveTeam(teamInfo) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/SaveTeam';
            const data = {
                body: teamInfo,
            };

            const teamResponse = await API.post(apiName, path, data);
            //console.log(teamResponse);
            return teamResponse;
        } catch (error) {
            console.error('error saving team: ', error);
        }
    }

    async function saveUser(userInfo) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Misc/SaveUser';
            const data = {
                body: userInfo,
            };

            const userResponse = await API.post(apiName, path, data);
            //console.log(userResponse);
            return userResponse;
        } catch (error) {
            console.error('error saving user: ', error);
        }
    }

    async function saveView(userID, message, appliesTo, savedView) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/SaveView';
            const data = {
                body: { userID: userID, message: message, applies_to: appliesTo, savedView: savedView },
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('Error saving view: ', error);
        }
    }

    return {
        AddUserToTeam: addUserToTeam,
        DeleteAllNotifications: deleteAllNotifications,
        DeleteOrg: deleteOrg,
        DeleteSavedSearch: deleteSavedSearch,
        DeleteSavedView: deleteSavedView,
        DeleteTeam: deleteTeam,
        DeleteUser: deleteUser,
        GetEmployeeTypes: getEmployeeTypes,
        GetEmployees: getEmployees,
        GetERateGeneralEmployees: getERateGeneralEmployees,
        GetNotificationCountForUser: getNotificationCountForUser,
        GetNotificationsForUser: getNotificationsForUser,
        GetNotificationTypes: getNotificationTypes,
        GetOrgs: getOrgs,
        GetSavedSearches: getSavedSearches,
        GetSavedViews: getSavedViews,
        GetTeams: getTeams,
        GetTeamsByOrgID: getTeamsByOrgID,
        GetTeamMembers: getTeamMembers,
        GetUser: getUser,
        GetUserOrUsers: getUserOrUsers,
        GetUserRoles: getUserRoles,
        GetUsers: getUsers,
        IsAdmin: isAdmin,
        MarkAllNotificationsAsRead: markAllNotificationsAsRead,
        MarkAllSelectedNotificationsAsReadUnreadOrDeleted: markAllSelectedNotificationsAsReadUnreadOrDeleted,
        MarkNotificationAsReadUnreadOrDeleted: markNotificationAsReadUnreadOrDeleted,
        MarkNotificationsAsX: markNotificationsAsX,
        RemoveUserFromTeam: removeUserFromTeam,
        SaveOrg: saveOrg,
        SaveSearch: saveSearch,
        SaveTeam: saveTeam,
        SaveUser: saveUser,
        SaveView: saveView,
    };
}

export default UserAPI;
