import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// MUI
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Custom components
import { BulkEditDropdown } from '../customcomponents/BulkEditDropdown.js';
import { BulkEditDatePicker } from '../customcomponents/BulkEditDatePicker.js';

export const Form471AppsBulkEdit = ({
    applicationOwnerEditOptions,
    bulkEditButtonClicked,
    ercAppStatusEditOptions,
    pruUpdatedRow,
    qaEstimatedFundingRequestEditOptions,
    qaReviewerEditOptions,
    qaReviewStatusEditOptions,
    qaSubmitterEditOptions,
    selectionModel,
    setBulkEditButtonClicked,
    setTrackerData,
    taskOwnerEditOptions,
    trackerData,
    trackersAPI,
    username,
}) => {
    // ################################### Bulk Editing #############################################
    const [openBulkEditDialog, setOpenBulkEditDialog] = React.useState(false);
    const [openBulkEditConfirmChangesTable, setOpenBulkEditConfirmChangesTable] = React.useState(false);
    const [nextButtonClicked, setNextButtonClicked] = React.useState(false);
    const [oldBulkEditRows, setOldBulkEditRows] = React.useState([]);
    const [newBulkEditRows, setNewBulkEditRows] = React.useState([]);

    const [bulkEditApplicationOwner, setBulkEditApplicationOwner] = React.useState('');
    const [bulkEditTaskOwners, setBulkEditTaskOwners] = React.useState('');
    const [bulkEditERCAppStatus, setBulkEditERCAppStatus] = React.useState('');
    const [bulkEditQAReviewStatus, setBulkEditQAReviewStatus] = React.useState('');
    const [bulkEditQASubmitter, setBulkEditQASubmitter] = React.useState('');
    const [bulkEditQAReviewer, setBulkEditQAReviewer] = React.useState('');
    const [bulkEditQADateSubmitted, setBulkEditQADateSubmitted] = React.useState('');
    const [bulkEditQADateNeeded, setBulkEditQADateNeeded] = React.useState('');
    const [bulkEditQAEstimatedFundingRequest, setBulkEditQAEstimatedFundingRequest] = React.useState('');
    const [bulkEditDateSentToClientToCertify, setBulkEditDateSentToClientToCertify] = React.useState('');

    const [bulkEditApplicationNotes, setBulkEditApplicationNotes] = React.useState('');
    const [bulkEditQAReviewerNotes, setBulkEditQAReviewerNotes] = React.useState('');
    const [allChanges, setAllChanges] = React.useState([]);
    const [changedAttributes, setChangedAttributes] = React.useState([]);

    // ################################### States for Has Multiple Values #############################################
    const [bulkEditApplicationOwnerHMV, setBulkEditApplicationOwnerHMV] = React.useState(false);
    const [bulkEditTaskOwnersHMV, setBulkEditTaskOwnersHMV] = React.useState(false);
    const [bulkEditERCAppStatusHMV, setBulkEditERCAppStatusHMV] = React.useState(false);
    const [bulkEditDateSentToClientToCertifyHMV, setBulkEditDateSentToClientToCertifyHMV] = React.useState(false);
    const [bulkEditQAReviewStatusHMV, setBulkEditQAReviewStatusHMV] = React.useState(false);
    const [bulkEditQADateSubmittedHMV, setBulkEditQADateSubmittedHMV] = React.useState(false);
    const [bulkEditQADateNeededHMV, setBulkEditQADateNeededHMV] = React.useState(false);
    const [bulkEditQAEstimatedFundingRequestHMV, setBulkEditQAEstimatedFundingRequestHMV] = React.useState(false);
    const [bulkEditQAReviewerHMV, setBulkEditQAReviewerHMV] = React.useState(false);
    const [bulkEditQASubmitterHMV, setBulkEditQASubmitterHMV] = React.useState(false);

    // ################################### Initial Values #############################################
    const [bulkEditApplicationOwnerIV, setBulkEditApplicationOwnerIV] = React.useState('');
    const [bulkEditTaskOwnersIV, setBulkEditTaskOwnersIV] = React.useState('');
    const [bulkEditERCAppStatusIV, setBulkEditERCAppStatusIV] = React.useState('');
    const [bulkEditQAReviewStatusIV, setBulkEditQAReviewStatusIV] = React.useState('');
    const [bulkEditQASubmitterIV, setBulkEditQASubmitterIV] = React.useState('');
    const [bulkEditQAReviewerIV, setBulkEditQAReviewerIV] = React.useState('');
    const [bulkEditQADateSubmittedIV, setBulkEditQADateSubmittedIV] = React.useState('');
    const [bulkEditQADateNeededIV, setBulkEditQADateNeededIV] = React.useState('');
    const [bulkEditQAEstimatedFundingRequestIV, setBulkEditQAEstimatedFundingRequestIV] = React.useState('');
    const [bulkEditDateSentToClientToCertifyIV, setBulkEditDateSentToClientToCertifyIV] = React.useState('');

    const [confirmButtonIsDisabled, setConfirmButtonIsDisabled] = React.useState(false);
    const [cancelButtonIsDisabled, setCancelButtonIsDisabled] = React.useState(false);
    const [backButtonIsDisabled, setBackButtonIsDisabled] = React.useState(false);
    const [bulkEditProgressIsOpen, setBulkEditProgressIsOpen] = React.useState(false);

    // Adds newly-selected existing data to a state
    React.useEffect(() => {
        setOldBulkEditSelectedRows();
    }, [selectionModel]);

    // Creates the displayed changes on the "confirmation page"
    React.useEffect(() => {
        // if (oldBulkEditRows.length > 0 && openBulkEditConfirmChangesTable) {
        if (oldBulkEditRows.length > 0) {
            const all_changes = findChangesBetweenRows(oldBulkEditRows, newBulkEditRows);
            if (all_changes.length !== 0) {
                // console.log('[promptBulkEditChangesConfirmation][all_changes] =', all_changes);
                // console.log('[promptBulkEditChangesConfirmation][changedAttributes] = ', changedAttributes);
                setAllChanges(all_changes);
            }

            // Determine the unique set of changed attributes across all selected records
            const changedAttributes = [...new Set(all_changes.flatMap((ea_change) => Object.keys(ea_change.changes)))];
            // console.log('[promptBulkEditChangesConfirmation][changedAttributes] =', changedAttributes);
            setChangedAttributes(changedAttributes);
        }
    }, [nextButtonClicked]);

    // After the user clicks the next button, add the newly modified data/records to a state
    React.useEffect(() => {
        if (newBulkEditRows.length > 0) {
            setOpenBulkEditConfirmChangesTable(true);
        }
    }, [newBulkEditRows]);

    // After processRunUpdate, find and update the corresponding row in trackerData (needed to prevent display errors during bulk editing)
    React.useEffect(() => {
        const updatedTrackerData = trackerData.map((row) => {
            if (row.form471AppNum === pruUpdatedRow.form471AppNum) {
                return pruUpdatedRow; // Replace with the updated row
            }
            return row; // Return unchanged row
        });

        setTrackerData(updatedTrackerData);
    }, [pruUpdatedRow]);

    // Triggered when the user clicks the BE button in the TrackerDataGrid
    React.useEffect(() => {
        if (bulkEditButtonClicked === true) {
            handleBulkEditButtonClicked();
            setBulkEditButtonClicked(false);
        }
    }, [bulkEditButtonClicked]);

    // If value is a number, return it.  If value is text then search the dropdown options for the numeric value (id).
    const correctTheDropdownValue = (value, ddoptions) => {
        // If value is a number, return it.  If value is text then search the dropdown options for the numeric value (id).
        if (value === null || value === undefined) {
            return 0;
        }
        if (Number.isInteger(value)) {
            return value;
        }
        if (
            value === '' ||
            value.toLowerCase() === '<default to primary contact>' ||
            value.toLowerCase() === '<not set>' ||
            value.toLowerCase() === '<blank>'
        ) {
            return 0;
        }

        let ddoption = ddoptions.find((element) => element.text === value);
        let ddvalue = ddoption?.value;
        return ddvalue;
    };

    // TODO: Review why this is actually necessary in BE
    const setApplicationOwnerUserID = (value) => {
        if (Number.isInteger(value)) {
            return value;
        } else {
            return value || 0;
        }
    };

    // Extracts first and last name from object
    const getUserDisplayName = (user) => {
        return user ? user.first_name + ' ' + user.last_name : '';
    };

    // Provides a display text based on a provided ID and array of options
    const getOtherDisplayName = (id, dropDownOptions) => {
        if (!id) {
            return '';
        }

        const results = dropDownOptions.find((option) => option.value === id);
        return results.text;
    };

    // Used to potentially extract 1+ ('First Last') names from the task owners array of objects
    const getTaskOwnerNames = (value) => {
        if (!value || value.length === 0) {
            return '';
        }
        let ary = value.map((tu) => {
            return getUserDisplayName(tu);
        });
        return ary.join(', ');
    };

    // Date handling
    const create_todayJSDate = () => {
        // Create a 'today' JavaScript Date for DataGrid 'date' edit fields.
        let today = new Date();
        today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero.
        return new Date(today.getTime() + today.getTimezoneOffset() * 60 * 1000);
    };

    // Date handling
    const convert_JSDate_to_yyyymmddString = (jsdate) => {
        // Input: Javascript Date object.
        // Output: Date string in the format "yyyy-mm-dd".  Time was pushed back as per the local timezone offset.
        if (!(jsdate instanceof Date)) {
            console.error('Error in convert_JSDate_to_yyyymmddString: Input was not a JavaScript Date object.');
            return null;
        }
        let jsdate2 = new Date(jsdate.getTime() - jsdate.getTimezoneOffset() * 60 * 1000);
        return jsdate2.toISOString().split('T')[0];
    };

    // V2 Date handling
    const addDaysToDateNeeded = (dateStr) => {
        // Split the date string into year, month, day
        const [year, month, day] = dateStr.split('-').map(Number);

        // Month - 1 because the months are zero-indexed
        // const date = new Date(Date.UTC(year, month - 1, day));
        const date = new Date(year, month - 1, day);

        // Getting the day of the week (0-6, Sunday-Saturday)
        const dayOfTheWeek = date.getDay();

        // Clone of the date (for adding the dates later)
        let adjustedDate = new Date(date);

        // If it's Monday, move the date to the closest Wednesday (2 days until Wednesday)
        if (dayOfTheWeek === 1) {
            adjustedDate.setDate(date.getDate() + 2);
        }
        // If it's Tuesday, move the date to the closest Thursday (3 days to Thursday)
        else if (dayOfTheWeek === 2) {
            adjustedDate.setDate(date.getDate() + 2);
        }
        // If it's Wednesday, move the date to the closest Friday
        else if (dayOfTheWeek === 3) {
            adjustedDate.setDate(date.getDate() + 2); // 2 days to Friday
        }
        // If it's Thursday or Friday, move the date to the closest Tuesday (9 - dayOfTheWeek gives the number of days to Tuesday)
        else if (dayOfTheWeek === 4 || dayOfTheWeek === 5) {
            adjustedDate.setDate(date.getDate() + (9 - dayOfTheWeek));
        }
        // If it's Saturday, move the date to the closest Tuesday (3 days to Tuesday)
        else if (dayOfTheWeek === 6) {
            adjustedDate.setDate(date.getDate() + 3);
        }
        // If it's Sunday, move the date to the closest Tuesday (2 days to Tuesday)
        else if (dayOfTheWeek === 0) {
            adjustedDate.setDate(date.getDate() + 2);
        }

        return adjustedDate.toISOString().split('T')[0];
    };

    // ERC Date handling
    const addDaysToDateNeededERC = (dateStr) => {
        if (!dateStr) {
            return dateStr;
        }

        let date = new Date(dateStr);
        const weekday = date.getDay(); // (0-6, Sunday-Saturday)

        // Add 2 days to the date by default
        let daysToAdd = 2;

        // Thursday/Friday (4 days Until Monday)
        if (weekday === 4 || weekday === 5) {
            daysToAdd = 4;
        }
        // Saturday (3 days until Tuesday)
        else if (weekday === 6) {
            daysToAdd = 3;
        }
        // Sunday (2 days until Tuesday)
        else if (weekday === 0) {
            daysToAdd = 2;
        }
        date.setDate(date.getDate() + daysToAdd);

        // Returns the date in YYYY-MM-DD format
        return date.toISOString().split('T')[0];
    };

    // Ran *only* if the user has selected a new ERC status (value 20/ready) after the next button has been clicked
    const checkIfEmptyAndFillQAFields = (uedata) => {
        let bSetToDefaults = false;

        if (!uedata.qa_form471app) {
            bSetToDefaults = true;
        } else {
            let ExistingSubmitterUserID = uedata.qaSubmitterUserId;
            let ExistingDateSubmitted = uedata.qaDateSubmitted;
            let ExistingDateNeeded = uedata.qaDateNeeded;

            if (ExistingSubmitterUserID == null && ExistingDateSubmitted == null && ExistingDateNeeded == null) {
                //console.log('qa_form471app is not empty but QA Submitter, QA Date Submitted, and QA Date Needed are empty.');
                bSetToDefaults = true;
            }
        }

        if (bSetToDefaults) {
            let newQASubmitterUserId = -1;
            if (
                uedata.form471app.application_owner_user_id !== null &&
                uedata.form471app.application_owner_user_id !== 0
            ) {
                newQASubmitterUserId = uedata.form471app.application_owner_user_id;
            } else {
                newQASubmitterUserId = uedata.resolvedApplicationOwner.id;
            }

            let bOnQASubmitterDropdownList = false;
            let ddoption = qaSubmitterEditOptions.find((element) => element.value === newQASubmitterUserId);
            if (ddoption) {
                bOnQASubmitterDropdownList = true;
            }

            if (bOnQASubmitterDropdownList) {
                uedata.qaSubmitterUserId = newQASubmitterUserId;
            }

            // let today, defaultQADateNeeded = create_todayJSDate();
            let today = create_todayJSDate();
            uedata.qaDateSubmitted = convert_JSDate_to_yyyymmddString(today);
            uedata.qaDateNeeded = addDaysToDateNeededERC(today);

            return uedata;
        }
        return uedata;
    };

    // Changed attribute to column header mapping
    const attributeHeaderMapping = {
        applicationOwnerUserId: 'Application Owner',
        taskOwnerUserId: 'Task Owner',
        ercAppStatusId: 'ERC App Status',
        qaReviewStatusId: 'QA Review Status',
        qaSubmitterUserId: 'QA Submitter',
        qaReviewerUserId: 'QA Reviewer',
        qaDateSubmitted: 'QA Date Submitted',
        qaDateNeeded: 'QA Date Needed',
        qaEstimatedFundingRequest: 'QA Estimated Funding Request',
        dateSentToClientToCertify: 'Date Sent to Client to Certify',
        form471AppNotes: 'Application Notes',
        qaNotes: 'QA Reviewer Notes',
    };

    // New changed record's attribute to old record's attribute mapping
    const newToOldAttrMapping = {
        // applicationOwnerUserId: 'application_owner_user_id',
        applicationOwnerUserId: 'resolvedApplicationOwner',
        taskOwnerUserId: 'task_users',
        ercAppStatusId: 'erc_form471_app_status_id',
        qaReviewStatusId: 'qa_review_status_id',
        qaSubmitterUserId: 'qa_submitter_user_id',
        qaReviewerUserId: 'qa_review_user_id',
        qaDateSubmitted: 'qa_date_submitted',
        qaDateNeeded: 'qa_date_needed',
        qaEstimatedFundingRequest: 'qa_estimated_funding_request',
        dateSentToClientToCertify: 'date_sent_to_client_to_certify',
        form471AppNotes: 'form471_app_notes',
        qaNotes: 'qa_notes',
    };

    // The "0 value"/none select option for each dropdown
    const emptyValueDisplayMapping = {
        applicationOwnerUserId: '<Default to Primary Contact>',
        taskOwnerUserId: '<Not Set>',
        ercAppStatusId: '<Not Set>',
        qaReviewStatusId: 'Not Set',
        qaSubmitterUserId: '<Not Set>',
        qaReviewerUserId: '<Not Set>',
    };

    // A mapping of attribute to state setter to check if any key in a Bulk Edit action has any varying values and set true/false accordingly
    const attributeBEStateMapping = {
        // applicationOwnerUserId: setBulkEditApplicationOwnerHMV,
        resolvedApplicationOwner: [
            setBulkEditApplicationOwnerHMV,
            setBulkEditApplicationOwnerIV,
            setBulkEditApplicationOwner,
        ],
        task_users: [setBulkEditTaskOwnersHMV, setBulkEditTaskOwnersIV, setBulkEditTaskOwners],
        erc_form471_app_status_id: [setBulkEditERCAppStatusHMV, setBulkEditERCAppStatusIV, setBulkEditERCAppStatus],
        qa_review_status_id: [setBulkEditQAReviewStatusHMV, setBulkEditQAReviewStatusIV, setBulkEditQAReviewStatus],
        qa_submitter_user_id: [setBulkEditQASubmitterHMV, setBulkEditQASubmitterIV, setBulkEditQASubmitter],
        qa_review_user_id: [setBulkEditQAReviewerHMV, setBulkEditQAReviewerIV, setBulkEditQAReviewer],
        qa_date_submitted: [setBulkEditQADateSubmittedHMV, setBulkEditQADateSubmittedIV, setBulkEditQADateSubmitted],
        qa_date_needed: [setBulkEditQADateNeededHMV, setBulkEditQADateNeededIV, setBulkEditQADateNeeded],
        qa_estimated_funding_request: [
            setBulkEditQAEstimatedFundingRequestHMV,
            setBulkEditQAEstimatedFundingRequestIV,
            setBulkEditQAEstimatedFundingRequest,
        ],
        date_sent_to_client_to_certify: [
            setBulkEditDateSentToClientToCertifyHMV,
            setBulkEditDateSentToClientToCertifyIV,
            setBulkEditDateSentToClientToCertify,
        ],
    };

    // Cycles through the *bulk selected* records, and for each attribute, checks if there's any difference in value for that attr within the dataset, and sets the respective state to true if so. Used in checkIfAttrHasMultipleValues once the Bulk Edit button is clicked
    const checkIfAttrHasMultipleValues = (selected_records, attributeStateMapping) => {
        // console.log('[checkIfAttrHasMultipleValues][selected_records] =', selected_records);
        // console.log('[checkIfAttrHasMultipleValues][attributeStateMapping] =', attributeStateMapping);
        for (const [key, valueSetters] of Object.entries(attributeStateMapping)) {
            let allHaveThisValue = '';
            const areAllValuesTheSame = selected_records.every((ea_obj) => {
                if (key === 'resolvedApplicationOwner') {
                    allHaveThisValue = selected_records[0][key].id;
                    return ea_obj[key].id === selected_records[0][key].id;
                }

                if (key === 'task_users') {
                    // Array of all provided task user IDs
                    const idArray1 = selected_records.map((ea_obj) =>
                        ea_obj['form471app'][key].map((ea_tu_obj) => ea_tu_obj.id).join(',')
                    );

                    // The "current" and only record (usually just one task user ID in the array) that needs a comparison
                    const firstObjIDValuesString = selected_records[0]['form471app'][key]
                        .map((ea_tu) => ea_tu.id)
                        .join(',');

                    // Check if each of the provided IDs are exactly the same as the first, if there's a difference, multiple values are present for TU
                    allHaveThisValue = firstObjIDValuesString;
                    return idArray1.every((ea_tu_id) => ea_tu_id === firstObjIDValuesString);
                }

                if (key.startsWith('qa')) {
                    const qaForm1 = ea_obj['qa_form471app'];
                    const qaForm2 = selected_records[0]['qa_form471app'];

                    // Checks if both QA forms are null
                    if (qaForm1 === null && qaForm2 === null) {
                        return true;
                    }

                    // Checks if both QA forms aren't null before comparison
                    if (qaForm1 !== null && qaForm2 !== null) {
                        const value1 = qaForm1[key];
                        const value2 = qaForm2[key];

                        // Checks if both values are null
                        if (value1 === null && value2 === null) {
                            return true;
                        }

                        // Checks if both values aren't null before comparison
                        if (value1 !== null && value2 !== null) {
                            allHaveThisValue = value2;
                            return value1 === value2;
                        } else {
                            // Handles the case where one value is null and the other isn't
                            return false;
                        }
                    } else {
                        // Handles the case where one QA form is null and the other isn't
                        return false;
                    }
                }

                allHaveThisValue = selected_records[0]['form471app'][key];
                return ea_obj['form471app'][key] === selected_records[0]['form471app'][key];
            });

            // If there are different values (AAVTS = false because every is all or nothing), then set MV to true.
            if (!areAllValuesTheSame) {
                valueSetters[0](true);
            } else {
                valueSetters[1](allHaveThisValue);
                valueSetters[2](allHaveThisValue);
            }
        }
    };

    // Comparing the old record values against the new edited values
    const findChangesBetweenRows = (oldRecords, newRecords) => {
        // console.log(oldRecords, newRecords);
        let changes = newRecords.map((newRecord, index) => {
            // Find the matching "old" record to compare the differences
            let oldRecord = oldRecords.find((oldRecord) => oldRecord.form471AppNum === newRecord.form471AppNum);
            // console.log('findChangesBetweenRows --> oldRecord: ', oldRecord);
            // console.log('findChangesBetweenRows --> newRecord: ', newRecord);

            const recordChanges = {};
            Object.keys(attributeHeaderMapping).forEach((attr_key) => {
                // Check if there's any new to old record attribute mapping required
                const translatedKey = newToOldAttrMapping[attr_key] || attr_key;
                let oldValue = oldRecord ? oldRecord[translatedKey] : undefined;
                let newValue = newRecord[attr_key];

                if (translatedKey === 'resolvedApplicationOwner') {
                    oldValue = oldRecord ? oldRecord[translatedKey]['id'] : undefined;
                }

                if (translatedKey === 'task_users') {
                    oldValue = oldRecord ? oldRecord.form471app[translatedKey][0]?.id : undefined;
                }

                if (translatedKey === 'erc_form471_app_status_id') {
                    oldValue = oldRecord ? oldRecord.form471app[translatedKey] : undefined;
                }

                if (translatedKey === 'qa_review_status_id') {
                    oldValue = oldRecord ? oldRecord.qa_form471app?.[translatedKey] : undefined;
                }

                if (translatedKey === 'qa_submitter_user_id') {
                    oldValue = oldRecord ? oldRecord.qa_form471app?.[translatedKey] : undefined;
                }

                if (translatedKey === 'qa_review_user_id') {
                    oldValue = oldRecord ? oldRecord.qa_form471app?.[translatedKey] : undefined;
                }

                if (translatedKey === 'qa_date_submitted') {
                    oldValue = oldRecord ? oldRecord.qa_form471app?.[translatedKey] : undefined;
                }

                if (translatedKey === 'qa_date_needed') {
                    oldValue = oldRecord ? oldRecord.qa_form471app?.[translatedKey] : undefined;
                }

                if (translatedKey === 'qa_estimated_funding_request') {
                    oldValue = oldRecord ? oldRecord.qa_form471app?.[translatedKey] : undefined;
                }

                if (translatedKey === 'date_sent_to_client_to_certify') {
                    oldValue = oldRecord ? oldRecord.form471app[translatedKey] : undefined;
                }

                if (translatedKey === 'form471_app_notes') {
                    oldValue = oldRecord ? oldRecord.form471app[translatedKey] : undefined;
                }

                if (translatedKey === 'qa_notes') {
                    oldValue = oldRecord ? oldRecord.qa_form471app?.[translatedKey] : undefined;
                }

                if (oldValue != newValue) {
                    recordChanges[attr_key] = { oldValue: oldValue, newValue: newValue };
                }
            });

            return {
                rowID: newRecord.form471AppNum,
                form471AppNum: newRecord.form471AppNum,
                nickname: newRecord.nickname,
                changes: recordChanges,
            };
        });
        // return changes.filter((ea_change) => Object.keys(ea_change.changes).length > 0); // Controls the 4 out of 5 scenario in regards to displaying unchanged yet selected rows
        return changes;
    };

    // Transformations are used for confirmation page display purposes only, after BE values have been set
    const applyOldRowTransformations = (rowID, value, attr) => {
        const matching_row = oldBulkEditRows.find((ea_row) => ea_row.form471AppNum === rowID);
        // console.log('applyOldRowTransformations --> old_matching_row: ', matching_row);

        if (!matching_row) {
            return null;
        }

        switch (attr) {
            case 'applicationOwnerUserId':
                return getUserDisplayName(matching_row.resolvedApplicationOwner);
            case 'taskOwnerUserId':
                return getTaskOwnerNames(matching_row.form471app.task_users);
            case 'qaSubmitterUserId':
                return getOtherDisplayName(matching_row.qa_form471app.qa_submitter_user_id, qaSubmitterEditOptions);
            case 'qaReviewerUserId':
                return getOtherDisplayName(matching_row.qa_form471app.qa_review_user_id, qaReviewerEditOptions);
            case 'qaReviewStatusId':
                return getOtherDisplayName(matching_row.qa_form471app.qa_review_status_id, qaReviewStatusEditOptions);
            case 'ercAppStatusId':
                return getOtherDisplayName(value, ercAppStatusEditOptions);
            case 'form471AppNotes':
                // return matching_row.form471app.form471_app_notes
                return null;
            case 'qaNotes':
                return matching_row.qa_form471app.qa_notes;
            default:
                return value;
        }
    };

    // Transformations are used for confirmation page display purposes only, after BE values have been set
    const applyNewRowTransformations = (rowID, value, attr) => {
        // const matching_row = newBulkEditRows.find((ea_row) => ea_row.form471AppNum === rowID);
        // console.log('applyNewRowTransformations --> new_matching_row: ', matching_row);

        // if (!matching_row) {
        //     return null;
        // }

        const characterLimit = 250;
        switch (attr) {
            case 'applicationOwnerUserId':
                return getOtherDisplayName(value, applicationOwnerEditOptions);
            case 'taskOwnerUserId':
                return getOtherDisplayName(value, taskOwnerEditOptions);
            case 'qaSubmitterUserId':
                return getOtherDisplayName(value, qaSubmitterEditOptions);
            case 'qaReviewerUserId':
                return getOtherDisplayName(value, qaReviewerEditOptions);
            case 'qaReviewStatusId':
                return getOtherDisplayName(value, qaReviewStatusEditOptions);
            case 'ercAppStatusId':
                return getOtherDisplayName(value, ercAppStatusEditOptions);
            default:
                if (value.length > characterLimit) {
                    return `${value.substring(0, characterLimit)}...`;
                }
                return value;
        }
    };

    // Runs whenever a new row is selected and adds that corresponding row data to oldBulkEditRows (to later be compared for differences)
    const setOldBulkEditSelectedRows = () => {
        let oldBulkEditRowsTemp = [];

        if (selectionModel.length > 0) {
            selectionModel.forEach((rowID) => {
                const row = trackerData.find((row) => row.form471AppNum === rowID);
                if (row) oldBulkEditRowsTemp.push(row);
            });
            // console.log('[setOldBulkEditSelectedRows] oldBulkEditRowsTemp =', oldBulkEditRowsTemp);
            setOldBulkEditRows(oldBulkEditRowsTemp);
            return oldBulkEditRowsTemp;
        }
    };

    // Prevents the user from clicking outside of the dialog to exit, and throws a window if they cancel with values selected
    const handleBulkEditClose = (event, reason) => {
        // Disable exiting if user clicked outside the dialog.
        // User can exit by clicking the "Cancel" button or pressing escape.

        // Handles clicking outside of the dialog
        if (reason === 'backdropClick') {
            return;
        }

        // If they searched and selected values then confirm they want to exit.
        if (
            (!isEmptyChecker(bulkEditApplicationOwner) && bulkEditApplicationOwner !== bulkEditApplicationOwnerIV) ||
            (!isEmptyChecker(bulkEditTaskOwners) && bulkEditTaskOwners !== bulkEditTaskOwnersIV) ||
            (!isEmptyChecker(bulkEditQASubmitter) && bulkEditQASubmitter !== bulkEditQASubmitterIV) ||
            (!isEmptyChecker(bulkEditQAReviewer) && bulkEditQAReviewer !== bulkEditQAReviewerIV) ||
            (!isEmptyChecker(bulkEditQAReviewStatus) && bulkEditQAReviewStatus !== bulkEditQAReviewStatusIV) ||
            (!isEmptyChecker(bulkEditERCAppStatus) && bulkEditERCAppStatus !== bulkEditERCAppStatusIV) ||
            (!isEmptyChecker(bulkEditQADateSubmitted) && bulkEditQADateSubmitted !== bulkEditQADateSubmittedIV) ||
            (!isEmptyChecker(bulkEditQADateNeeded) && bulkEditQADateNeeded !== bulkEditQADateNeededIV) ||
            (!isEmptyChecker(bulkEditDateSentToClientToCertify) &&
                bulkEditDateSentToClientToCertify !== bulkEditDateSentToClientToCertifyIV) ||
            (!isEmptyChecker(bulkEditQAEstimatedFundingRequest) &&
                bulkEditQAEstimatedFundingRequest !== bulkEditQAEstimatedFundingRequestIV) ||
            bulkEditApplicationNotes ||
            bulkEditQAReviewerNotes
        ) {
            const confirmation = window.confirm('Close without finishing?');
            if (!confirmation) return;
        }

        // Reset the state if the user confirmed they want to exit.
        resetThreeBulkStateSets();
    };

    const handleBulkEditCloseConfirmationPage = (event, reason) => {
        return;
    };

    // Opens the initial bulk editing dialog after the user clicks the Bulk Edit button
    const handleBulkEditButtonClicked = () => {
        const oldBulkEditRowsTemp = setOldBulkEditSelectedRows();
        // console.log('[handleBulkEditButtonClicked][oldBulkEditRowsTemp] =', oldBulkEditRowsTemp);
        setOpenBulkEditDialog(true);
        checkIfAttrHasMultipleValues(oldBulkEditRowsTemp, attributeBEStateMapping);
    };

    // Prepends the newly-typed note to the corresponding already-existing note (ea record at a time)
    const handleAddNotesBulk = (oldValue, newValue) => {
        const currentDate = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
        const newNote = `${currentDate} (${username.email.split('@')[0]}) - ${newValue}\n\n`;
        if (!oldValue) return newNote;
        return `${newNote}${oldValue}`;
        // return newNote;
    };

    // Sets the newly-edited BE records to state after applying transformations after the user clicks the Next button
    const handleBulkEditNextButtonClicked = () => {
        // Look at result.ercAppStatuses in the 471Tracker for values
        const ERCForm471AppStatusId_ReadyForQA = 20;
        const ERCForm471AppStatusId_OutOfQA = 21;
        const QAReviewStatusId_QAReturned = 3;
        const QAReviewStatusId_QAPassed = 4;
        const QAReviewStatusId_QAWillNotBeReviewed = 5;

        const bulkSelectedRows = selectionModel.map((rowID) => {
            const specificRow = trackerData.find((row) => row.form471AppNum === rowID);
            // console.log('[handleBulkEditNextButtonClicked][specificRow] =', specificRow);

            let uedata = {
                ...specificRow,

                applicationOwnerUserId: !isEmptyChecker(bulkEditApplicationOwner) // Did they provide a value that's not 1?
                    ? setApplicationOwnerUserID(bulkEditApplicationOwner)
                    : specificRow.resolvedApplicationOwner['id'], // "//~ params.value is the employee name returned by valueGetter (resolvedApplicationOwner) unless/until the user changes the dropdown."

                // applicationOwnerUserId: !isEmptyChecker(bulkEditApplicationOwner) // Did they provide a value that's not 1?
                // ? setApplicationOwnerUserID(bulkEditApplicationOwner)
                // : specificRow.form471app.application_owner_user_id,

                // taskOwnerUserId: !isEmptyChecker(bulkEditTaskOwners)
                //     ? correctTheDropdownValue(bulkEditTaskOwners, taskOwnerEditOptions)
                //     : specificRow.form471app.task_users[0]?.['id'],

                taskOwnerUserId: !isEmptyChecker(bulkEditTaskOwners)
                    ? Number(bulkEditTaskOwners)
                    : specificRow.form471app.task_users[0]?.['id'],

                ercAppStatusId: !isEmptyChecker(bulkEditERCAppStatus)
                    ? bulkEditERCAppStatus
                    : specificRow.form471app.erc_form471_app_status_id,

                qaReviewStatusId: !isEmptyChecker(bulkEditQAReviewStatus)
                    ? correctTheDropdownValue(bulkEditQAReviewStatus, qaReviewStatusEditOptions)
                    : specificRow.qa_form471app?.qa_review_status_id,

                qaSubmitterUserId: !isEmptyChecker(bulkEditQASubmitter)
                    ? correctTheDropdownValue(bulkEditQASubmitter, qaSubmitterEditOptions)
                    : specificRow.qa_form471app?.qa_submitter_user_id,

                qaReviewerUserId: !isEmptyChecker(bulkEditQAReviewer)
                    ? correctTheDropdownValue(bulkEditQAReviewer, qaReviewerEditOptions)
                    : specificRow.qa_form471app?.qa_review_user_id,

                qaDateSubmitted: bulkEditQADateSubmitted
                    ? bulkEditQADateSubmitted
                    : specificRow.qa_form471app?.qa_date_submitted,

                qaDateNeeded: bulkEditQADateNeeded ? bulkEditQADateNeeded : specificRow.qa_form471app?.qa_date_needed,

                qaEstimatedFundingRequest: bulkEditQAEstimatedFundingRequest
                    ? bulkEditQAEstimatedFundingRequest
                    : specificRow.qa_form471app?.qa_estimated_funding_request,

                dateSentToClientToCertify: bulkEditDateSentToClientToCertify
                    ? bulkEditDateSentToClientToCertify
                    : specificRow.form471app.date_sent_to_client_to_certify,

                form471AppNotes: bulkEditApplicationNotes
                    ? handleAddNotesBulk(specificRow.form471app.form471_app_notes, bulkEditApplicationNotes)
                    : specificRow.form471app.form471_app_notes,

                qaNotes: bulkEditQAReviewerNotes
                    ? handleAddNotesBulk(specificRow.qa_form471app?.qa_notes, bulkEditQAReviewerNotes)
                    : specificRow.qa_form471app?.qa_notes,
            };

            if (bulkEditERCAppStatus === 20) {
                checkIfEmptyAndFillQAFields(uedata);
            }

            // If the QA Date Submitted was EXPLICITLY selected selected during BE
            if (bulkEditQADateSubmitted && !bulkEditQADateNeeded) {
                const dateNeededResults = addDaysToDateNeeded(bulkEditQADateSubmitted);
                uedata.qaDateNeeded = dateNeededResults;
            }

            // Business rule brought from backend. If the ERC App Status was EXPLICITLY selected during BE
            if (bulkEditQAReviewStatus && bulkEditQAReviewStatus !== specificRow.qa_form471app?.qa_review_status_id) {
                const qaStatusIDs = [
                    QAReviewStatusId_QAReturned, // 3
                    QAReviewStatusId_QAPassed, // 4
                    QAReviewStatusId_QAWillNotBeReviewed, // 5
                ];

                if (qaStatusIDs.includes(bulkEditQAReviewStatus)) {
                    if (uedata['ercAppStatusId'] === ERCForm471AppStatusId_ReadyForQA) {
                        // Ready for QA == 20
                        uedata['ercAppStatusId'] = ERCForm471AppStatusId_OutOfQA; // Out of QA == 20
                    }
                }
            }

            return uedata;
        });

        setNewBulkEditRows(bulkSelectedRows);
        setOpenBulkEditDialog(false);
        setNextButtonClicked(false);
    };

    // Reopens the landing Bulk Editing component
    const handleBulkEditBackButtonClicked = () => {
        setOpenBulkEditConfirmChangesTable(false);
        setOpenBulkEditDialog(true);
        setNextButtonClicked(false);
    };

    // Resets all states dealing with BE
    const resetThreeBulkStateSets = () => {
        handleBulkEditResetState();
        handleBulkEditHasMultipleValuesResetState();
        handleBulkEditInitialValuesResetState();
    };

    // Allows the user to keep BE changes if the API call fails
    const resetStateForFailedAPIResponse = () => {
        setOpenBulkEditConfirmChangesTable(false);
        setOpenBulkEditDialog(false);
        setBulkEditProgressIsOpen(false);
        setConfirmButtonIsDisabled(false);
        setCancelButtonIsDisabled(false);
        setBackButtonIsDisabled(false);
        setNextButtonClicked(false);
    };

    // Clear out all of the bulk editing state management (after cancel or successful saving)
    const handleBulkEditResetState = () => {
        setBulkEditApplicationOwner('');
        setBulkEditTaskOwners('');
        setBulkEditERCAppStatus('');
        setBulkEditQAReviewStatus('');
        setBulkEditQASubmitter('');
        setBulkEditQAReviewer('');
        setBulkEditQADateSubmitted('');
        setBulkEditQADateNeeded('');
        setBulkEditQAEstimatedFundingRequest('');
        setBulkEditDateSentToClientToCertify('');
        setBulkEditApplicationNotes('');
        setBulkEditQAReviewerNotes('');
        setOpenBulkEditDialog(false);
        setOpenBulkEditConfirmChangesTable(false);
        setBulkEditProgressIsOpen(false);
        setConfirmButtonIsDisabled(false);
        setCancelButtonIsDisabled(false);
        setBackButtonIsDisabled(false);
        setOldBulkEditRows([]);
        setNewBulkEditRows([]);
        setNextButtonClicked(false);
        setChangedAttributes([]);
        setAllChanges([]);
    };

    // Clear out all of the bulk editing multiple values state management (after cancel or successful saving)
    const handleBulkEditHasMultipleValuesResetState = () => {
        setBulkEditApplicationOwnerHMV(false);
        setBulkEditTaskOwnersHMV(false);
        setBulkEditERCAppStatusHMV(false);
        setBulkEditDateSentToClientToCertifyHMV(false);
        setBulkEditQAReviewStatusHMV(false);
        setBulkEditQADateSubmittedHMV(false);
        setBulkEditQADateNeededHMV(false);
        setBulkEditQAEstimatedFundingRequestHMV(false);
        setBulkEditQAReviewerHMV(false);
        setBulkEditQASubmitterHMV(false);
    };

    // Clear out all of the bulk editing initial values
    const handleBulkEditInitialValuesResetState = () => {
        setBulkEditApplicationOwnerIV('');
        setBulkEditTaskOwnersIV('');
        setBulkEditERCAppStatusIV('');
        setBulkEditDateSentToClientToCertifyIV('');
        setBulkEditQAReviewStatusIV('');
        setBulkEditQADateSubmittedIV('');
        setBulkEditQADateNeededIV('');
        setBulkEditQAEstimatedFundingRequestIV('');
        setBulkEditQAReviewerIV('');
        setBulkEditQASubmitterIV('');
    };

    // Makes the save call after the user clicks on the Confirm button
    const handleBulkEditConfirmButtonClicked = () => {
        setConfirmButtonIsDisabled(true);
        setCancelButtonIsDisabled(true);
        setBackButtonIsDisabled(true);
        setBulkEditProgressIsOpen(true);

        handleBulkEditSaveAPICall();
    };

    // Excludes numeric 0 from being falsy for dropdowns
    const isEmptyChecker = (value) => {
        if (value === null || value === undefined) return true;
        if (typeof value === 'string' && value.trim().length === 0) return true;
        return false;
    };

    // Checks if any values have been selected, used to control disabled attributes
    const isNextButtonDisabled = !(
        (!isEmptyChecker(bulkEditApplicationOwner) && bulkEditApplicationOwner !== bulkEditApplicationOwnerIV) ||
        (!isEmptyChecker(bulkEditTaskOwners) && bulkEditTaskOwners !== bulkEditTaskOwnersIV) ||
        (!isEmptyChecker(bulkEditQASubmitter) && bulkEditQASubmitter !== bulkEditQASubmitterIV) ||
        (!isEmptyChecker(bulkEditQAReviewer) && bulkEditQAReviewer !== bulkEditQAReviewerIV) ||
        (!isEmptyChecker(bulkEditQAReviewStatus) && bulkEditQAReviewStatus !== bulkEditQAReviewStatusIV) ||
        (!isEmptyChecker(bulkEditERCAppStatus) && bulkEditERCAppStatus !== bulkEditERCAppStatusIV) ||
        (!isEmptyChecker(bulkEditQADateSubmitted) && bulkEditQADateSubmitted !== bulkEditQADateSubmittedIV) ||
        (!isEmptyChecker(bulkEditQADateNeeded) && bulkEditQADateNeeded !== bulkEditQADateNeededIV) ||
        (!isEmptyChecker(bulkEditQAEstimatedFundingRequest) &&
            bulkEditQAEstimatedFundingRequest !== bulkEditQAEstimatedFundingRequestIV) ||
        (!isEmptyChecker(bulkEditDateSentToClientToCertify) &&
            bulkEditDateSentToClientToCertify !== bulkEditDateSentToClientToCertifyIV) ||
        bulkEditApplicationNotes ||
        bulkEditQAReviewerNotes
    );

    // Saves the Bulk Editing data and controls related Toast pop-ups
    const handleBulkEditSaveAPICall = async () => {
        try {
            // Creating the new data structure for bulk editing
            const recordIDs = selectionModel.map((ea_item) => {
                return { form471AppNum: ea_item };
            });

            const findNewData = () => {
                const newData = newBulkEditRows.map((record) => {
                    return changedAttributes.reduce((newRecord, field) => {
                        newRecord['form471AppNum'] = record['form471AppNum'];

                        if (record.hasOwnProperty(field)) {
                            newRecord[field] = record[field];
                        }
                        return newRecord;
                    }, {});
                });
                return newData;
            };

            const createRequestTimestamp = () => {
                const currentDatetime = new Date();
                const year = currentDatetime.getFullYear();
                const month = String(currentDatetime.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const day = String(currentDatetime.getDate()).padStart(2, '0');
                const hour = String(currentDatetime.getHours()).padStart(2, '0');
                const minute = String(currentDatetime.getMinutes()).padStart(2, '0');

                return `${year}-${month}-${day}T${hour}:${minute}`;
            };

            const formattedData = {
                recordIDs: recordIDs,
                fieldsToChange: changedAttributes,
                newData: findNewData(),
                numRowsToBeEdited: recordIDs.length,
                requestTimestamp: createRequestTimestamp(),
            };
            console.log('[handleBulkEditSaveAPICall] formattedData =', formattedData);
            const apiResponse = await trackersAPI.Form471TrackerSaveBulkV2(formattedData);

            if (apiResponse.length > 0) {
                const parsedAPIResponse = apiResponse.map((item) => JSON.parse(item));
                console.log('[handleBulkEditSaveAPICall][parsedAPIResponse] =', parsedAPIResponse);

                const updatedRecordsMap = new Map(parsedAPIResponse.map((record) => [record.form471AppNum, record]));
                // console.log('[handleBulkEditSaveAPICall][updatedRecordsMap] =', updatedRecordsMap);

                // Mapping through the existing trackerData and replacing records that have been updated
                const updatedTrackerData = trackerData.map((record) => {
                    const uniqueID = record.form471AppNum;
                    return updatedRecordsMap.has(uniqueID) ? updatedRecordsMap.get(uniqueID) : record;
                });

                // Update the state with the new data
                setTrackerData(updatedTrackerData);

                resetThreeBulkStateSets();
                toast.success(`Successfully bulk edited (${selectionModel.length}) applications`, {
                    autoClose: 3000,
                });
            } else {
                resetStateForFailedAPIResponse();
                toast.error(`Error saving bulk edits for (${selectionModel.length}) applications`);
            }
        } catch (error) {
            resetStateForFailedAPIResponse();
            toast.error('An error occurred while saving bulk edits');
            console.error('Error in handleBulkEditSaveAPICall:', error);
        }
    };

    // ################################### Return Component #############################################
    return (
        <>
            <Dialog open={openBulkEditDialog} onClose={handleBulkEditClose} PaperProps={{ style: { minWidth: '50%' } }}>
                <DialogTitle sx={{ fontSize: '1.0rem' }}>
                    {`Bulk editing for (${selectionModel.length}) applications`}
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={2}>
                        {/* FIRST COLUMN */}
                        <Grid item xs={6}>
                            {/* ERC App Status Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-erc-app-status-label'>
                                    {bulkEditERCAppStatusHMV ? 'ERC App Status (Multiple Values)' : 'ERC App Status'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditERCAppStatusIV}
                                    value={bulkEditERCAppStatus}
                                    setValue={setBulkEditERCAppStatus}
                                    dropDownOptions={ercAppStatusEditOptions}
                                    label='erc-app-status-label'
                                    multipleValues={bulkEditERCAppStatusHMV}
                                />
                            </FormControl>

                            {/* Application Owner Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-application-owner-label'>
                                    {bulkEditApplicationOwnerHMV
                                        ? 'Application Owner (Multiple Values)'
                                        : 'Application Owner'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditApplicationOwnerIV}
                                    value={bulkEditApplicationOwner}
                                    setValue={setBulkEditApplicationOwner}
                                    dropDownOptions={applicationOwnerEditOptions}
                                    label='application-owner-label'
                                    multipleValues={bulkEditApplicationOwnerHMV}
                                />
                            </FormControl>

                            {/* Task Owners Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-task-owners-label'>
                                    {bulkEditTaskOwnersHMV ? 'Task Owners (Multiple Values)' : 'Task Owners'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditTaskOwnersIV}
                                    value={bulkEditTaskOwners}
                                    setValue={setBulkEditTaskOwners}
                                    dropDownOptions={taskOwnerEditOptions}
                                    label='task-owners-label'
                                    multipleValues={bulkEditTaskOwnersHMV}
                                />
                            </FormControl>

                            {/* Date Sent to Client to Certify Date Picker */}
                            <BulkEditDatePicker
                                label={
                                    bulkEditDateSentToClientToCertifyHMV
                                        ? 'Date Sent to Client to Certify (Multiple Values)'
                                        : 'Date Sent to Client to Certify'
                                }
                                providedDate={bulkEditDateSentToClientToCertify}
                                initialDate={bulkEditDateSentToClientToCertifyIV}
                                onDateChange={(newDate) => setBulkEditDateSentToClientToCertify(newDate)}
                                multipleValues={bulkEditDateSentToClientToCertifyHMV}
                            />

                            <FormControl fullWidth={true} margin='normal'>
                                <TextField
                                    label='Application Notes'
                                    value={bulkEditApplicationNotes}
                                    id='form471_app_notes_textfield'
                                    onChange={(event) => setBulkEditApplicationNotes(event.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    multiline
                                    rows={10}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: bulkEditApplicationNotes ? 'orange' : '',
                                                borderWidth: bulkEditApplicationNotes ? '2px' : '1px',
                                            },
                                        },
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        {/* SECOND COLUMN */}
                        <Grid item xs={6}>
                            {/* QA Submitter Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-qa-submitter-label'>
                                    {bulkEditQASubmitterHMV ? 'QA Submitter (Multiple Values)' : 'QA Submitter'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditQASubmitterIV}
                                    value={bulkEditQASubmitter}
                                    setValue={setBulkEditQASubmitter}
                                    dropDownOptions={qaSubmitterEditOptions}
                                    label='qa-submitter-label'
                                    multipleValues={bulkEditQASubmitterHMV}
                                />
                            </FormControl>
                            {/* 471 QA Date Submitted Date Picker */}
                            <BulkEditDatePicker
                                label={
                                    bulkEditQADateSubmittedHMV
                                        ? 'QA Date Submitted (Multiple Values)'
                                        : 'QA Date Submitted'
                                }
                                providedDate={bulkEditQADateSubmitted}
                                initialDate={bulkEditQADateSubmittedIV}
                                onDateChange={(newDate) => setBulkEditQADateSubmitted(newDate)}
                                multipleValues={bulkEditQADateSubmittedHMV}
                            />
                            {/* 471 QA Date Needed Date Picker */}
                            <BulkEditDatePicker
                                label={bulkEditQADateNeededHMV ? 'QA Date Needed (Multiple Values)' : 'QA Date Needed'}
                                providedDate={bulkEditQADateNeeded}
                                initialDate={bulkEditQADateNeededIV}
                                onDateChange={(newDate) => setBulkEditQADateNeeded(newDate)}
                                multipleValues={bulkEditQADateNeededHMV}
                            />
                            {/* QA Estimated Funding Request Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-471-qa-estimated-funding-request-label'>
                                    {bulkEditQAEstimatedFundingRequestHMV
                                        ? 'QA Estimated Funding Request (Multiple Values)'
                                        : 'QA Estimated Funding Request'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditQAEstimatedFundingRequestIV}
                                    value={bulkEditQAEstimatedFundingRequest}
                                    setValue={setBulkEditQAEstimatedFundingRequest}
                                    dropDownOptions={qaEstimatedFundingRequestEditOptions}
                                    label='471-qa-estimated-funding-request-label'
                                    multipleValues={bulkEditQAEstimatedFundingRequestHMV}
                                />
                            </FormControl>

                            {/* 471 QA Status Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-471-qa-status-label'>
                                    {bulkEditQAReviewStatusHMV ? 'QA Status (Multiple Values)' : 'QA Status'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditQAReviewStatusIV}
                                    value={bulkEditQAReviewStatus}
                                    setValue={setBulkEditQAReviewStatus}
                                    dropDownOptions={qaReviewStatusEditOptions}
                                    label='471-qa-status-label'
                                    multipleValues={bulkEditQAReviewStatusHMV}
                                />
                            </FormControl>
                            {/* QA Reviewer Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-471-qa-reviewer-label'>
                                    {bulkEditQAReviewerHMV ? 'QA Reviewer (Multiple Values)' : 'QA Reviewer'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditQAReviewerIV}
                                    value={bulkEditQAReviewer}
                                    setValue={setBulkEditQAReviewer}
                                    dropDownOptions={qaReviewerEditOptions}
                                    label='471-qa-reviewer-label'
                                    multipleValues={bulkEditQAReviewerHMV}
                                />
                            </FormControl>
                            {/* QA Notes */}
                            <FormControl fullWidth={true} margin='normal'>
                                <TextField
                                    label='QA Reviewer Notes'
                                    value={bulkEditQAReviewerNotes}
                                    id='qa_notes_textfield'
                                    onChange={(event) => setBulkEditQAReviewerNotes(event.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    multiline
                                    rows={10}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: bulkEditQAReviewerNotes ? 'orange' : '',
                                                borderWidth: bulkEditQAReviewerNotes ? '2px' : '1px',
                                            },
                                        },
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => {
                            handleBulkEditNextButtonClicked();
                            setNextButtonClicked(true);
                        }}
                        disabled={isNextButtonDisabled}
                    >
                        Next
                    </Button>
                    <Button onClick={handleBulkEditClose}>Cancel</Button>{' '}
                </DialogActions>
            </Dialog>

            {/* Creates the "confirmation page" dialog and displays the changes between the old matching records and the newly-edited records within a table */}
            <Dialog
                open={openBulkEditConfirmChangesTable}
                onClose={() => {
                    handleBulkEditCloseConfirmationPage();
                }}
                aria-labelledby='bulk-edit-changes-confirmation-title'
                fullWidth
                maxWidth='lg'
            >
                {bulkEditProgressIsOpen && (
                    <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: (theme) => theme.zIndex.modal + 1,
                            display: 'flex',
                            position: 'absolute',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        open={bulkEditProgressIsOpen}
                        invisible={!bulkEditProgressIsOpen}
                    >
                        <CircularProgress color='inherit' />
                        <Typography variant='body1' sx={{ marginTop: 2, color: '#fff' }}>
                            Saving Bulk Edits...
                        </Typography>
                    </Backdrop>
                )}
                <DialogTitle id='bulk-edit-changes-confirmation-title' sx={{ fontSize: '1em' }}>
                    Confirm Bulk Edit Changes
                </DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper} sx={{ maxHeight: '50vh' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center' style={{ borderRight: '1px solid #ddd' }}>
                                        Application Number
                                    </TableCell>
                                    <TableCell align='center' style={{ borderRight: '1px solid #ddd' }}>
                                        Nickname
                                    </TableCell>
                                    {changedAttributes.map((ea_changed_attr) => (
                                        <TableCell
                                            key={ea_changed_attr}
                                            align='center'
                                            style={{ borderRight: '1px solid #ddd' }}
                                        >
                                            {attributeHeaderMapping[ea_changed_attr]}
                                            {ea_changed_attr.toLowerCase().includes('notes') && (
                                                <div style={{ fontSize: '0.75em', color: '#6c757d' }}>
                                                    (prepended to existing note)
                                                </div>
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allChanges.map((change_obj) => (
                                    <TableRow key={change_obj.rowID}>
                                        <TableCell align='center' style={{ borderRight: '1px solid #ddd' }}>
                                            {change_obj.form471AppNum}
                                        </TableCell>
                                        <TableCell align='center' style={{ borderRight: '1px solid #ddd' }}>
                                            {change_obj.nickname}
                                        </TableCell>
                                        {changedAttributes.map((ea_changed_attr) => (
                                            <TableCell
                                                key={`${change_obj.rowID}-${ea_changed_attr}`}
                                                align='center'
                                                style={{ whiteSpace: 'pre-wrap', borderRight: '1px solid #ddd' }}
                                            >
                                                {change_obj.changes[ea_changed_attr] ? (
                                                    ea_changed_attr.toLowerCase().includes('notes') ? (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            {change_obj.changes[ea_changed_attr].newValue !== undefined
                                                                ? '<Prepending Notes>'
                                                                : '<blank>'}
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <div>
                                                                {change_obj.changes[ea_changed_attr].oldValue !==
                                                                undefined
                                                                    ? applyOldRowTransformations(
                                                                          change_obj.rowID,
                                                                          change_obj.changes[ea_changed_attr].oldValue,
                                                                          ea_changed_attr
                                                                      ) || '<blank>'
                                                                    : '<blank>'}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <KeyboardArrowDownIcon style={{ color: '#4CAF50' }} />
                                                            </div>
                                                            <div>
                                                                {change_obj.changes[ea_changed_attr].newValue !==
                                                                undefined
                                                                    ? applyNewRowTransformations(
                                                                          change_obj.rowID,
                                                                          change_obj.changes[ea_changed_attr].newValue,
                                                                          ea_changed_attr
                                                                      ) || emptyValueDisplayMapping[ea_changed_attr]
                                                                    : '<blank>'}
                                                            </div>
                                                        </div>
                                                    )
                                                ) : (
                                                    'No Change'
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button disabled={backButtonIsDisabled} onClick={handleBulkEditBackButtonClicked}>
                        Back
                    </Button>
                    <Box flexGrow={1} display='flex' justifyContent='flex-end'>
                        <Button
                            onClick={handleBulkEditConfirmButtonClicked}
                            color='primary'
                            disabled={confirmButtonIsDisabled}
                        >
                            Confirm
                        </Button>
                        <Button disabled={cancelButtonIsDisabled} onClick={handleBulkEditClose}>
                            Cancel
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};
